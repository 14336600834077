import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getToken } from "../../../utils/token";
// import { errorToast } from "../../../response/errorToast";
import { setLoading } from "../loader/loaderSlice";

export const fetchExams = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    dispatch(fetchExamsStart());
    const token = getToken();
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/exams/list`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    dispatch(fetchExamsSuccess(response?.data));
  } catch (error) {
    // errorToast(error.response.data.errors);
    dispatch(fetchExamsFailure(error?.response?.data));
  } finally {
    dispatch(setLoading(false));
  }
};

const initialState = {
  exams: [],
  status: "idle",
  error: null,
};

const examSlice = createSlice({
  name: "exams",
  initialState,
  reducers: {
    fetchExamsStart: (state) => {
      state.status = "loading";
    },
    fetchExamsSuccess: (state, action) => {
      state.status = "succeeded";
      state.exams = action.payload;
    },
    fetchExamsFailure: (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.error;
    },
  },
});

export const { fetchExamsStart, fetchExamsSuccess, fetchExamsFailure } =
  examSlice.actions;

export default examSlice.reducer;
