import { createSlice } from "@reduxjs/toolkit";
// import { setLoading } from "../loaderSlice";
import axios from "axios";
import { errorToast } from "../../../response/errorToast";
import { setUserRole } from "../role/userRoleSlice";
import { setUserSubscription } from "../subscription/userSubscriptionSlice";
import { setLoading } from "../loader/loaderSlice";
import Cookies from "js-cookie";

const initialState = {
  user: null,
  status: "idle",
  error: null,
};

const userByIdSlice = createSlice({
  name: "userById",
  initialState,
  reducers: {
    fetchUserByIdStart: (state) => {
      state.status = "loading";
    },
    fetchUserByIdSuccess: (state, action) => {
      state.status = "succeeded";
      state.user = action.payload.data;
    },
    fetchUserByIdFail: (state, action) => {
      state.status = "failed";
      state.error = action;
    },
  },
});

export const { fetchUserByIdStart, fetchUserByIdSuccess, fetchUserByIdFail } =
  userByIdSlice.actions;

export default userByIdSlice.reducer;

export const fetchUserById = (id, token) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    dispatch(fetchUserByIdStart());

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/users/view/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const userRole = response?.data?.data?.is_staff;
    const userSubscription = response?.data?.data?.active_plan;
    const isActiveFreeTrial = response?.data?.data?.is_trial_active;
    const storedUserData = Cookies.get("userData");

    // Check if userData is available
    if (!storedUserData) {
      return null;
    }
    // Parse the retrieved string back into an object
    const parsedUserData = JSON.parse(storedUserData);
    // console.log(parsedUserData);
    parsedUserData.activePlan = userSubscription; // Replace "new_plan_value" with the desired value
    parsedUserData.isFreeTrialActive = isActiveFreeTrial;
    // Convert the updated object back to a JSON string
    const updatedUserData = JSON.stringify(parsedUserData);

    // Store the updated JSON string back into the cookies
    Cookies.set("userData", updatedUserData);
    // console.log(updatedUserData, "updatedUserData");
    dispatch(fetchUserByIdSuccess(response.data));
    dispatch(setUserRole(userRole));
    dispatch(setUserSubscription(userSubscription));
    return { success: true, data: response?.data };
  } catch (error) {
    // console.log(error);
    errorToast(error?.response?.data?.errors);
    dispatch(fetchUserByIdFail(error?.data));
  } finally {
    dispatch(setLoading(false));
  }
};
