import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  TableContainer,
  TableBody,
  TableCell,
  Table,
  TableHead,
  TableRow,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import { callApi } from "../../../api";
import { errorToast } from "../../../response/errorToast";
import ReactMarkdown from "react-markdown";
import moment from "moment";
import { GoAlertFill } from "react-icons/go";
import { setSubscriptionFromApi } from "../../../store/slices/subscription/selectors";
import { setLoading } from "../../../store/slices/loader/loaderSlice";
import Loader from "../../../common/loader";

const UserViewComprehension = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [comprehension, setComprehension] = useState("");
  const userState = useSelector((state) => state.userById.user);
  const [isSubscribed, setisSubscribed] = useState(false);

  const { id } = useParams();
  const formik = useFormik({
    initialValues: {
      name: "",
      question_type: "",
    },
  });

  const fetchData = async () => {
    try {
      dispatch(setLoading(true));
      const response = await callApi({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/comprehension/view/${id}/`,
      });
      setComprehension(response);
    } catch (error) {
      // console.log(error);
      errorToast(error.response.data.errors);
      if (
        error.response &&
        error.response.data &&
        error.response.data.errors &&
        error.response.data.errors.includes(
          "Comprehension with given id does not exist"
        )
      ) {
        navigate("*");
      }
    } finally {
      dispatch(setLoading(false));
    }
  };
  const { isLoading } = useSelector((state) => state.loader);
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    formik.setValues({
      name: comprehension?.data?.name || "",
      question_type: comprehension?.data?.question_type || "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comprehension]);

  const generateNewExplanation = () => {
    fetchData();
  };

  const subscription = useSelector(setSubscriptionFromApi);
  const listOfPlans = [
    "tutor_monthly",
    "intermediate_monthly",
    "tutor_six_monthly",
    "intermediate_six_monthly",
    "tutor_yearly",
    "intermediate_yearly",
  ];
  // const isSubscribed =
  //   subscription && listOfPlans.some((plan) => subscription.includes(plan));

  useEffect(() => {
    // If no subscription, check comprehension practice
    if (!subscription) {
      if (userState?.comprehension_practice_left === 0) {
        setisSubscribed(false);

        return;
      } else {
        setisSubscribed(userState?.comprehension_practice_left !== 0);
        return;
      }
    }
    // Check if the subscription includes any valid plans
    const hasValidPlan = subscription && listOfPlans?.some((plan) =>
      subscription.includes(plan)
    );
    if(!hasValidPlan){
      if (userState?.comprehension_practice_left === 0) {
        setisSubscribed(false);

        return;
      } else {
        setisSubscribed(userState?.comprehension_practice_left !== 0);
        return;
      }
    }
    setisSubscribed(!!hasValidPlan);
  }, [subscription, userState]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : isSubscribed ? (
        <>
          <Box className="content-header-title">
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="100%"
            >
              <Button
                className="action-btn"
                onClick={() => navigate(`/comprehension`)}
              >
                <IoMdArrowRoundBack className="icon-font" />
              </Button>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography variant="h2" component="h2">
                  View Comprehension
                </Typography>
                {/* <Button
                  variant="contained"
                  size="large"
                  className="primary-btn btn"
                  // type="submit"
                  disableRipple
                  onClick={() => {
                    navigate(`/comprehension/${comprehension?.data?.id}/attempt`);
                  }}
                >
                  {" "}
                  Practice
                </Button> */}
              </Box>
            </Box>
          </Box>
          <Box className="content-layout">
            <Box variant="div" component="div" className="createuser-wrap p-24">
              <Grid container spacing={4}>
                <Grid item sm={12} md={12} lg={12}>
                  <Box variant="div" component="div" className="user-detail">
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12} lg={12}>
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          width="100%"
                        >
                          <Box variant="div" component="div" flex={1}>
                            <Typography
                              variant="body1"
                              component="label"
                              className="label"
                            >
                              Comprehension Name
                            </Typography>

                            <Typography
                              variant="body2"
                              component="span"
                              className="value"
                            >
                              {formik.values.name}
                            </Typography>
                          </Box>
                          <Box variant="div" component="div" flex={1}>
                            <Typography
                              variant="body1"
                              component="label"
                              className="label"
                            >
                              Comprehension Category
                            </Typography>

                            <Typography
                              variant="body2"
                              component="span"
                              className="value"
                            >
                              {comprehension?.data?.category_name || "NA"}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <Box variant="div" component="div">
                          <Typography
                            variant="body1"
                            component="label"
                            className="label"
                          >
                            Question Type
                          </Typography>

                          <Typography
                            variant="body2"
                            component="span"
                            className="value"
                          >
                            {formik.values.question_type === "mcq"
                              ? "MCQ"
                              : "Short Answers"}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
              <Box
                variant="div"
                component="div"
                className="footer-btn"
                style={{ marginTop: "16px", height: "40px" }}
              >
                <Box variant="div" component="div" className="btn-group">
                 <Button
                    variant="contained"
                    size="large"
                    className="primary-btn btn"
                    // type="submit"
                    disableRipple
                    onClick={() => {
                      navigate(
                        `/comprehension/${comprehension?.data?.id}/attempt`
                      );
                    }}
                  >
                    {" "}
                    Quick Practice
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="content-layout">
            <TableContainer className="table-listing">
              <Table
                // sx={{ minWidth: 650 }}
                aria-label="table"
                className="listing-table"
              >
                <TableHead>
                  <TableRow className="head-row">
                    <TableCell align="left">Explanation</TableCell>
                    <TableCell align="right">
                      <Box style={{ height: "35px" }}>
                        <Button
                          variant="contained"
                          size="large"
                          className="primary-btn btn add-btn"
                          disableRipple
                          onClick={generateNewExplanation}
                        >
                          <Typography variant="body1" component="p">
                            View Alternate Explanation
                          </Typography>
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {comprehension?.data?.rule_explanation?.length === 0 ? (
                    <TableRow className="no-msg">
                      <TableCell
                        style={{
                          width: "100%",
                          // textAlign: "center",
                          fontSize: "16px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        className="no-msg"
                      >
                        Explanations are being generated in background&nbsp;
                        <CircularProgress
                          style={{
                            height: "25px",
                            width: "25px",
                            marginTop: "5px",
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ) : (
                    comprehension?.data?.rule_explanation?.map(
                      (exam, index) => (
                        <TableRow
                          key={exam.id}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                          // style={{ cursor: "pointer" }}
                          // onClick={() =>
                          //   handleQuestionOpen(exam?.id, exam?.explanation)
                          // }
                        >
                          <TableCell
                            align="left"
                            key={exam?.id}
                            colSpan={2}
                            className="attempt-exam"
                          >
                            <ReactMarkdown>{exam?.explanation}</ReactMarkdown>
                          </TableCell>
                        </TableRow>
                      )
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box className="content-layout">
            <TableContainer className="table-listing">
              <Table
                // sx={{ minWidth: 650 }}
                aria-label="table"
                className="listing-table"
              >
                <TableHead>
                  <TableRow className="head-row">
                    <TableCell align="left">Previous Attempts</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {comprehension?.data?.attempts?.length === 0 ? (
                    <TableRow className="no-msg">
                      <TableCell
                        style={{
                          width: "100%",
                          textAlign: "center",
                          fontSize: "16px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        className="no-msg"
                      >
                        No attempts found for this exam{" "}
                      </TableCell>
                    </TableRow>
                  ) : (
                    comprehension?.data?.attempts?.map((exam, index) => (
                      <TableRow
                        key={exam.id}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          navigate(
                            `/comprehension/${exam?.id}/comprehension-feedback`
                          )
                        }
                      >
                        <TableCell align="left" key={exam?.id} colSpan={2}>
                          Attempted on{" "}
                          {moment(exam?.created_at).format("MM-DD-YYYY")}
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </>
      ) : (
        <Box className="content-layout" marginTop="32px">
          <Box
            variant="div"
            component="div"
            className="tab-wrapper user-listing"
          >
            <Box className="access-denied-found">
              <Box className="page-inner">
                <GoAlertFill fontSize="65px" style={{ marginBottom: "40px" }} />
                {/* <Typography variant="h3" component="h3">
                  Access Denied
                </Typography>
                <Typography variant="body1" component="p">
                  You are not subscribed to Essay Practice Module. Please
                  purchase atleast basic plan to access it.
                </Typography> */}

                <Typography
                  variant="h3"
                  component="h3"
                  style={{ textTransform: "initial" }}
                >
                  <Box>
                    You have no free Comprehension Exam Left. Please Upgrade to
                    the atleast Basic plan.
                  </Box>
                </Typography>
                <Typography variant="body1" component="p">
                  <Box>
                    Please subscribe to join our vibrant community of learners!
                  </Box>
                </Typography>
                <Box style={{ height: "35px" }}>
                  <Button
                    variant="contained"
                    size="large"
                    className="primary-btn btn add-btn"
                    disableRipple
                    onClick={() => navigate(`/my-plan`)}
                  >
                    Upgrade Plan
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};
export default UserViewComprehension;
