import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { successToast } from "../../../response/successToast";
import { getToken } from "../../../utils/token";
import { IoMdArrowRoundBack } from "react-icons/io";
import { callApi } from "../../../api";
import { attemptComprehension } from "../../../store/slices/comprehension/attemptComprehensionSlice";
import { capitalizeMessage } from "../../../utils/helper";
import { setLoading } from "../../../store/slices/loader/loaderSlice";
import Loader from "../../../common/loader";
import { fetchUserById } from "../../../store/slices/users/userByIdSlice";
import { setSubscriptionFromApi } from "../../../store/slices/subscription/selectors";

const AttemptComprehension = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [comprehension, setComprehension] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState({}); // State to store selected options
  const [questionCompletion, setQuestionCompletion] = useState({}); // State to track question completion
  const [isAllShortAnswersFilled, setIsAllShortAnswersFilled] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);
  const token = getToken();
  const userState = useSelector((state) => state.userById.user);
  // console.log("userState", userState);
  const subscription = useSelector(setSubscriptionFromApi);
  const [rule, setRule] = useState();
  const [practiceId, setPracticeId] = useState();
  const [answers, setAnswers] = useState({});
  // const [isSubscribed, setisSubscribed] = useState(false);
  const listOfPlans = [
    "tutor_monthly",
    "intermediate_monthly",
    "tutor_six_monthly",
    "intermediate_six_monthly",
    "tutor_yearly",
    "intermediate_yearly",
  ];
  const hasValidPlan =
    subscription && listOfPlans?.some((plan) => subscription.includes(plan));
  const handleAnswerChange = (questionId, answer) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: answer,
    }));

    // Check if all short answers are filled
    setIsAllShortAnswersFilled((prevIsAllShortAnswersFilled) => {
      // Check if any answer becomes empty
      const isAnyAnswerEmpty = Object.values({
        ...answers, // Use current state of answers
        [questionId]: answer, // Update the specific question's answer
      }).some((value) => value === "");

      // If any answer is empty, disable the button
      if (isAnyAnswerEmpty) {
        return false;
      }

      // If all answers are filled, enable the button
      return (
        Object.keys(answers).length ===
        comprehension?.data?.practice_set?.practice_questions?.length
      );
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(setLoading(true));
        const response = await callApi({
          method: "get",
          url: `${process.env.REACT_APP_API_URL}/comprehension/view/${id}/`,
        });
        setComprehension(response);
        // Initialize question completion status
        const completion = {};
        response.data.practice_set.practice_questions.forEach((question) => {
          completion[question.id] = false;
        });
        setQuestionCompletion(completion);
      } catch (error) {
        console.error(error);
        // Handle error and navigation here
      } finally {
        dispatch(setLoading(false));
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  const { isLoading } = useSelector((state) => state.loader);
  // Check if all questions are answered
  useEffect(() => {
    const isAllQuestionsAnswered = Object.values(questionCompletion).every(
      (completion) => completion
    );
    // Enable or disable button based on completion status
    const submitButton = document.getElementById("submit-btn");
    if (submitButton) {
      submitButton.disabled = !isAllQuestionsAnswered;
    }
  }, [questionCompletion]);

  const handleSubmit = async () => {
    const response = {};

    Object.keys(selectedOptions).forEach((questionId) => {
      response[questionId] = parseInt(selectedOptions[questionId]);
    });

    const payload = {
      comprehension_id: rule,
      practice_set_id: practiceId,
      response: response,
    };
    try {
      const response = await dispatch(attemptComprehension(payload, token));
      if (response.success) {
        successToast("Practice submitted successfully");
        navigate(`/comprehension/${response?.data?.id}/comprehension-feedback`);
      } else {
        console.error("Error creating user:", response.error);
      }
    } catch (error) {
      console.error("Error creating lead:", error.message);
    } finally {
      // Enable the save button after the API call is completed
      // setIsButtonDisabled(false);
    }
    // Dispatch the attemptComprehension action here if needed
  };

  const handleShortAnswerSubmit = async () => {
    const response = {};
    for (const questionId in answers) {
      // Add the question ID and answer to the payload object
      response[questionId] = answers[questionId];
    }

    const payload = {
      comprehension_id: rule,
      practice_set_id: practiceId,
      response: response,
    };
    try {
      const response = await dispatch(attemptComprehension(payload, token));
      if (response.success) {
        successToast("Practice submitted successfully");
        navigate(`/comprehension/${response?.data?.id}/comprehension-feedback`);
      } else {
        console.error("Error creating user:", response.error);
      }
    } catch (error) {
      console.error("Error creating lead:", error.message);
    } finally {
      // Enable the save button after the API call is completed
      // setIsButtonDisabled(false);
    }
  };

  const handleOptionChange = (questionId, optionId) => {
    setSelectedOptions({
      ...selectedOptions,
      [questionId]: optionId,
    });
    // Update question completion status
    const updatedCompletion = {
      ...questionCompletion,
      [questionId]: true,
    };
    setQuestionCompletion(updatedCompletion);
  };

  useEffect(() => {
    if (comprehension) {
      setRule(comprehension?.data?.practice_set?.comprehension_id);
      setPracticeId(comprehension?.data?.practice_set?.id);
    }
  }, [comprehension]);

  useEffect(() => {
    const checkHasAccess = () => {
      if (subscription && hasValidPlan) {
        return;
      } else if (subscription && !hasValidPlan) {
        if (userState?.comprehension_practice_left === 0) {
          navigate("/comprehension");
          return;
        } else {
          return;
        }

      } else if (
        !subscription &&
        userState?.comprehension_practice_left !== 0
      ) {
        return;
      } else if (
        !subscription &&
        userState?.comprehension_practice_left === 0
      ) {
        navigate("/comprehension");
        return;
      }
    };

    checkHasAccess();

    if (token && userState?.id && !hasFetched) {
      dispatch(fetchUserById(userState.id, token));
      setHasFetched(true); // Mark as fetched
    }
  }, [
    userState?.id,
    userState?.comprehension_practice_left,
    token,
    navigate,
    dispatch,
    hasFetched,
  ]);
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Box className="content-header-title">
            <Button
              className="action-btn"
              onClick={() => navigate(`/comprehension`)}
            >
              <IoMdArrowRoundBack className="icon-font" />
            </Button>
            <Typography variant="h2" component="h2">
              {capitalizeMessage(comprehension?.data?.name)}&nbsp;Practice
            </Typography>
          </Box>
          {comprehension?.data?.question_type === "mcq" ? (
            <Box className="content-layout">
              <Box
                variant="div"
                component="div"
                className="createuser-wrap p-24"
              >
                <Grid container spacing={4}>
                  <Grid item sm={12} md={12} lg={12}>
                    <Box variant="div" component="div" className="user-detail">
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                          {comprehension?.data?.practice_set?.paragraph && (
                            <Typography
                              variant="body1"
                              component="label"
                              className="label"
                              mb={4}
                            >
                              Passage: <br />
                              {comprehension?.data?.practice_set?.paragraph
                                ?.text
                                ? comprehension?.data?.practice_set?.paragraph
                                  ?.text
                                : comprehension?.data?.practice_set?.paragraph}
                            </Typography>
                          )}
                          {comprehension?.data?.practice_set?.practice_questions.map(
                            (question) => (
                              <Box
                                key={question.id}
                                variant="div"
                                component="div"
                                mb={3}
                              >
                                <Typography
                                  variant="body1"
                                  component="label"
                                  className="label"
                                >
                                  {question?.id}) {question?.question}
                                </Typography>
                                <RadioGroup
                                  aria-label={`question-${question?.id}`}
                                  name={`question_type_${question?.id}`}
                                  value={selectedOptions[question?.id] || ""}
                                  onChange={(event) =>
                                    handleOptionChange(
                                      question.id,
                                      event.target.value
                                    )
                                  }
                                >
                                  {question.options.map((option) => (
                                    <FormControlLabel
                                      key={option.id}
                                      value={option.id.toString()}
                                      control={<Radio />}
                                      label={option.text}
                                    />
                                  ))}
                                </RadioGroup>
                              </Box>
                            )
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
                <Box
                  variant="div"
                  component="div"
                  className="footer-btn"
                  style={{ marginTop: "16px", height: "40px" }}
                >
                  <Box
                    variant="div"
                    component="div"
                    className="btn-group short-answer"
                  >
                    <Button
                      id="submit-btn" // Added an id to the button for easier targeting
                      variant="contained"
                      size="large"
                      className="primary-btn btn"
                      type="button"
                      disableRipple
                      onClick={() => handleSubmit()}
                    >
                      Submit
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box className="content-layout">
              <Box
                variant="div"
                component="div"
                className="createuser-wrap p-24"
              >
                <Grid container spacing={4}>
                  <Grid item sm={12} md={12} lg={12}>
                    <Box variant="div" component="div" className="user-detail">
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                          {comprehension?.data?.practice_set?.paragraph && (
                            <Typography
                              variant="body1"
                              component="label"
                              className="label"
                              mb={4}
                            >
                              Passage: <br />
                              {comprehension?.data?.practice_set?.paragraph}
                            </Typography>
                          )}
                          {comprehension?.data?.practice_set?.practice_questions.map(
                            (question) => (
                              <Box
                                key={question.id}
                                variant="div"
                                component="div"
                                mb={3}
                              >
                                <Typography
                                  variant="body1"
                                  component="label"
                                  className="label"
                                >
                                  {question?.id}) {question?.question}
                                </Typography>
                                <TextField
                                  multiline
                                  rows={2}
                                  className="input-field"
                                  fullWidth
                                  id={`answer-${question?.id}`}
                                  // label="Enter your response"
                                  variant="outlined"
                                  size="small"
                                  placeholder="Enter your response"
                                  value={answers[question.id] || ""}
                                  onChange={(e) =>
                                    handleAnswerChange(
                                      question.id,
                                      e.target.value
                                    )
                                  }
                                />
                              </Box>
                            )
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
                <Box
                  variant="div"
                  component="div"
                  className="footer-btn"
                  style={{ marginTop: "16px", height: "40px" }}
                >
                  <Box
                    variant="div"
                    component="div"
                    className="btn-group short-answer"
                  >
                    <Button
                      id="submit-btn" // Added an id to the button for easier targeting
                      variant="contained"
                      size="large"
                      className="primary-btn btn"
                      type="button"
                      disableRipple
                      onClick={() => {
                        handleShortAnswerSubmit();
                      }}
                      disabled={!isAllShortAnswersFilled}
                    >
                      Submit
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default AttemptComprehension;
