import {
  Box,
  TableContainer,
  TableBody,
  Table,
  TableHead,
  TableCell,
  TableRow,
  Typography,
  Button,
  TextField,
  InputAdornment,
  MenuItem,
  Tabs,
  Tab,
  Tooltip,
} from "@mui/material";
import { MdFavorite, MdFavoriteBorder } from "react-icons/md";
import { Search as SearchIcon } from "@mui/icons-material";
import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchComprehension } from "../../../store/slices/comprehension/comprehensionSlice";
import { GoAlertFill } from "react-icons/go";
import { setSubscriptionFromApi } from "../../../store/slices/subscription/selectors";
import Loader from "../../../common/loader";
import { fetchCategories } from "../../../store/slices/category/categorySlice";
import { getToken } from "../../../utils/token";
import {
  addTofavourites,
  removeFromfavourites,
  fetchUserfavourites,
} from "../../../store/slices/favourite/favouriteSlice";
import { errorToast } from "../../../response/errorToast";
import { fetchUserById } from "../../../store/slices/users/userByIdSlice";
import getuserData from "../../../utils/userData";

const UserComprehensionList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const comprehension = useSelector(
    (state) => state.comprehension.comprehension
  );
  const favourites = useSelector(
    (state) => state.favourite.favourites?.favourites?.comprehension
  );
  const [localFavorites, setLocalFavorites] = useState(new Set());
  const [isCheckingSubscription, setIsCheckingSubscription] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [filteredComprehension, setFilteredComprehension] = useState([]);
  const [categories, setCategories] = useState([]);
  const token = getToken();
  const subscription = useSelector(setSubscriptionFromApi);
  const [activeTab, setActiveTab] = useState(0);
  const userState = useSelector((state) => state.userById.user);
  const err = useSelector((state) => state.comprehension.error);
  const [hasValidPlan, sethasValidPlan] = useState(false);
  const [isSubscribed, setisSubscribed] = useState(false);
  const listOfPlans = [
    "tutor_monthly",
    "intermediate_monthly",
    "tutor_six_monthly",
    "intermediate_six_monthly",
    "tutor_yearly",
    "intermediate_yearly",
  ];
  const { isLoading } = useSelector((state) => state.loader);
  // const isLoadingFavorite = useSelector((state) => state.loader.isLoading);
  const [isLoadingFavorite, setIsLoadingFavorite] = useState(false);

  useEffect(() => {
    const favoriteIds = new Set(favourites?.map((fav) => fav.item_id));
    setLocalFavorites(favoriteIds);
  }, [favourites, activeTab]);

  const isFavorite = useCallback(
    (compId) => {
      return localFavorites?.has(compId);
    },
    [localFavorites]
  );

  const toggleFavorite = async (comprehension) => {
    const payload = {
      module_name: "comprehension",
      item_id: [comprehension.id],
    };

    try {
      setIsLoadingFavorite(true);
      // Optimistically update local state
      const newLocalFavorites = new Set(localFavorites);
      if (isFavorite(comprehension.id)) {
        newLocalFavorites.delete(comprehension.id);
        await dispatch(removeFromfavourites(payload));
      } else {
        newLocalFavorites.add(comprehension.id);
        await dispatch(addTofavourites(payload));
      }

      // Update local favorites immediately
      setLocalFavorites(newLocalFavorites);
      dispatch(fetchUserfavourites());
    } catch (error) {
      // Revert local state if API call fails
      console.error("Failed to toggle favorite:", error);
      errorToast(["Failed to update favorites"]);
    } finally {
      setIsLoadingFavorite(false);
    }
  };

  const fetchComprehensionCategories = async () => {
    try {
      const response = await dispatch(
        fetchCategories((module = "comprehension"), token)
      );

      if (response.success) {
        setCategories(response.data.comprehension_categories || []);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  // Filter and search logic
  useEffect(() => {
    if (comprehension?.comprehensions) {
      let filtered = [...comprehension?.comprehensions];

      // Apply category filter
      if (selectedCategory !== "all") {
        filtered = filtered?.filter(
          (comprehension) => comprehension.category_name === selectedCategory
        );
      }

      // Apply search filter
      if (searchQuery) {
        const query = searchQuery?.toLowerCase();
        filtered = filtered?.filter(
          (comprehension) =>
            comprehension?.name?.toLowerCase().includes(query) ||
            (comprehension?.category_name &&
              comprehension?.category_name.toLowerCase().includes(query))
        );
      }

      // Active Tab filter
      if (activeTab === 0) {
        filtered = filtered?.filter((comp) => {
          if (Array.isArray(favourites)) {
            const hasFavourite = favourites?.some((fav) => {
              const isMatch = fav.item_id === comp.id;
              return isMatch;
            });
            return hasFavourite;
          }
          return false;
        });
      }
      filtered.sort((a, b) => a.category_name.localeCompare(b.category_name));
      setFilteredComprehension(filtered);
    }
  }, [
    comprehension?.comprehensions,
    selectedCategory,
    searchQuery,
    activeTab,
    favourites,
    isFavorite,
  ]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleTabChange = (_, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    // Simulate fetching subscription status
    if (subscription !== undefined) {
      setIsCheckingSubscription(false);
    }
  }, [subscription]);

  useEffect(() => {
    dispatch(fetchComprehension({}));
    if (!isCheckingSubscription && isSubscribed) {
      fetchComprehensionCategories();
      dispatch(fetchUserfavourites());
    }
  }, [dispatch, isSubscribed, isCheckingSubscription, activeTab]);

  useEffect(() => {
    // If no subscription, check comprehension practice
    if (!subscription) {
      if (userState?.comprehension_practice_left <= 0) {
        setisSubscribed(false);

        return;
      } else {
        setisSubscribed(userState?.comprehension_practice_left > 0);
        return;
      }
    }
    // Check if the subscription includes any valid plans
    const validPlan = subscription && listOfPlans?.some((plan) =>
      subscription.includes(plan)
    );
    sethasValidPlan(validPlan)
    if(!validPlan){
      if (userState?.comprehension_practice_left <= 0) {
        setisSubscribed(false);

        return;
      } else {
        setisSubscribed(userState?.comprehension_practice_left > 0);
        return;
      }
    }

    setisSubscribed(validPlan);
  }, [subscription, userState]);

  useEffect(() => {
    if (token && userState) {
      dispatch(fetchUserById(userState?.id, token));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {isLoading || isCheckingSubscription ? (
        <Loader />
      ) : (
        <>
          {(!subscription || (subscription && !hasValidPlan)) && (
            <Box className="left-attempt-container">
              <Typography>
                Enjoy <span style={{ color: "black" }}>4</span> Complimentary
                Comprehensions Sessions for free. Unlock More with Our Premium
                Plans.
              </Typography>
              <Typography>
                Free Comprehensions Left :&nbsp;
                <span style={{ color: "black" }}>
                  {" "}
                  {userState?.comprehension_practice_left}&nbsp;/&nbsp;4
                </span>
              </Typography>
            </Box>
          )}
          <Box className="content-layout" marginTop="32px">
            <Box
              variant="div"
              component="div"
              className="tab-wrapper user-listing"
            >
              {isSubscribed ? (
                <>
                  {/* Tabs for "My Comprehension" and "All Comprehensions" */}
                  <Tabs
                    value={activeTab}
                    onChange={handleTabChange}
                    textColor="primary"
                    indicatorColor="primary"
                    variant="fullWidth"
                  >
                    <Tab
                      sx={{
                        borderBottom: activeTab === 0 ? 2 : 0,
                        borderColor: "primary.main",
                        fontWeight: 900,
                        fontSize: "15px",
                        textTransform: "none",
                      }}
                      label="My Comprehensions"
                    />
                    <Tab
                      sx={{
                        borderBottom: activeTab === 1 ? 2 : 0,
                        borderColor: "primary.main",
                        fontWeight: 900,
                        fontSize: "15px",
                        textTransform: "none",
                      }}
                      label="All"
                    />
                  </Tabs>
                  {/* Search and Filter Controls */}

                  <Box sx={{ display: "flex", gap: 2, m: 3 }}>
                    <TextField
                      placeholder="Search comprehension..."
                      variant="outlined"
                      size="small"
                      value={searchQuery}
                      onChange={handleSearchChange}
                      sx={{ flexGrow: 1, mt: 2 }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      select
                      size="small"
                      value={selectedCategory}
                      onChange={handleCategoryChange}
                      sx={{ minWidth: 200, mt: 2 }}
                    >
                      <MenuItem value="all">All Categories</MenuItem>
                      {categories?.map((category) => (
                        <MenuItem key={category.name} value={category.name}>
                          {category.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Box>
                  <TableContainer className="table-listing">
                    <Table aria-label="table" className="listing-table">
                      <TableHead>
                        <TableRow className="head-row">
                          <TableCell
                            align="left"
                            sx={{ paddingLeft: "30px", width: "50%" }}
                          >
                            Comprehension Name
                          </TableCell>
                          {selectedCategory === "all" && (
                            <TableCell align="left" sx={{ width: "30%" }}>
                              Category
                            </TableCell>
                          )}
                          <TableCell align="center">
                            {activeTab === 0
                              ? "Remove From Favourites"
                              : " Add To Favourites"}
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {filteredComprehension?.length === 0 ? (
                          <TableRow className="no-msg">
                            <TableCell
                              colSpan={selectedCategory === "all" ? 3 : 2}
                              style={{
                                width: "100%",
                                textAlign: "center",
                                fontSize: "16px",
                              }}
                              className="no-msg"
                            >
                              {activeTab === 0
                                ? "No Comprehensions Availabe In Your Favorites"
                                : "No Comprehensions Found"}
                            </TableCell>
                          </TableRow>
                        ) : (
                          filteredComprehension?.map((comprehension) => {
                            const isButtonDisabled =
                              favourites?.length >= 6 &&
                              !isFavorite(comprehension.id);
                            return (
                              <TableRow
                                key={comprehension.id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <TableCell
                                  align="left"
                                  sx={{ paddingLeft: "30px" }}
                                  onClick={() =>
                                    navigate(
                                      `/comprehension/${comprehension?.id}/view`
                                    )
                                  }
                                >
                                  {comprehension?.name}
                                </TableCell>
                                {selectedCategory === "all" && (
                                  <TableCell align="left" >
                                    {categories.find(
                                      (cat) =>
                                        cat.name ===
                                        comprehension?.category_name
                                    )?.name || "Uncategorized"}
                                  </TableCell>
                                )}
                                <TableCell align="left" sx={{
                                    display: "flex",
                                    // border: "2px solid red",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}>
                                  <Tooltip
                                    title={
                                      isButtonDisabled
                                        ? "You can have at most 6 Comprehensions in favourite list"
                                        : ""
                                    }
                                    placement="bottom"
                                    arrow
                                  >
                                    <span>
                                      <Button
                                        disabled={
                                          isButtonDisabled || isLoadingFavorite
                                        }
                                        onClick={() =>
                                          toggleFavorite(comprehension)
                                        }
                                        style={{
                                          cursor: "pointer",
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        {isFavorite(comprehension.id) ? (
                                          <MdFavorite className="text-red-500 w-6 h-6" />
                                        ) : (
                                          <MdFavoriteBorder className="text-gray-600 w-6 h-6" />
                                        )}
                                      </Button>
                                    </span>
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            );
                          })
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              ) : (
                <Box className="access-denied-found">
                  <Box className="page-inner">
                    <GoAlertFill
                      fontSize="65px"
                      style={{ marginBottom: "40px" }}
                    />
                    {/* <Typography variant="h3" component="h3">
                  Access Denied
                </Typography>
                <Typography variant="body1" component="p">
                  You are not subscribed to Comprehension and comprehensions Module. Please
                  purchase atleast Intermediate plan to access it.
                </Typography> */}
                    <Typography
                      variant="h3"
                      component="h3"
                      style={{ textTransform: "initial" }}
                    >
                      <Box>
                        You Have No Free Comprehension Practices Left. Please Unlock
                        Even More With Our Premium Plans.
                      </Box>
                    </Typography>
                    <Typography variant="body1" component="p">
                      <Box>
                        Please subscribe to join our vibrant community of
                        learners!
                      </Box>
                    </Typography>
                    <Box style={{ height: "35px" }}>
                      <Button
                        variant="contained"
                        size="large"
                        className="primary-btn btn add-btn"
                        disableRipple
                        onClick={() => navigate(`/my-plan`)}
                      >
                        upgrade Plan
                      </Button>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default UserComprehensionList;
