import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorToast } from "../../../response/errorToast";
// import { setLoading } from "../loader/loaderSlice";

const initialState = {
  isLoading: false,
  success: false,
  error: null,
};

const createExamSlice = createSlice({
  name: "createExam",
  initialState,
  reducers: {
    createExamStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    createExamSuccess: (state) => {
      state.isLoading = false;
      state.success = true;
      state.error = null;
    },
    createExamFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
  },
});

export const { createExamStart, createExamSuccess, createExamFailure } =
  createExamSlice.actions;

export default createExamSlice.reducer;

export const createExam = (examData, token) => async (dispatch) => {
  try {
    // dispatch(setLoading(true));
    dispatch(createExamStart());

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/exams/create`,
      examData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = response.data;
    dispatch(createExamSuccess());
    // successToast("Lead creatde successfully!");
    return { success: true, message: data?.message, exam_id:data?.exam_id };
  } catch (error) {
    errorToast(error.response.data.errors);
    dispatch(createExamFailure(error.message));

    return { success: false, error: error.response.message };
  } finally {
    // dispatch(setLoading(false));
  }
};
