import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getToken } from "../../../utils/token";
// import { errorToast } from "../../../response/errorToast";
import { setLoading } from "../loader/loaderSlice";

export const fetchComprehension = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    dispatch(fetchComprehensionStart());
    const token = getToken();
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/comprehension/list`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    dispatch(fetchComprehensionSuccess(response?.data));
  } catch (error) {
    // errorToast(error.response.data.errors);
    dispatch(fetchComprehensionFailure(error?.response?.data));
  } finally {
    dispatch(setLoading(false));
  }
};

const initialState = {
  comprehension: [],
  status: "idle",
  error: null,
};

const comprehensionSlice = createSlice({
  name: "comprehension",
  initialState,
  reducers: {
    fetchComprehensionStart: (state) => {
      state.status = "loading";
    },
    fetchComprehensionSuccess: (state, action) => {
      state.status = "succeeded";
      state.comprehension = action.payload;
    },
    fetchComprehensionFailure: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
  },
});

export const {
  fetchComprehensionStart,
  fetchComprehensionSuccess,
  fetchComprehensionFailure,
} = comprehensionSlice.actions;

export default comprehensionSlice.reducer;
