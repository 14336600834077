import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  IconButton
} from "@mui/material";
import { MdModeEditOutline } from "react-icons/md";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { successToast } from "../../response/successToast";
import { getToken } from "../../utils/token";
import * as Yup from "yup";
import { callApi } from "../../api";
import { errorToast } from "../../response/errorToast";
import { editSetting } from "../../store/slices/settings/editSettingsSlice";
import { useNavigate } from "react-router-dom";
import {
  createCategory,
  fetchCategories,
  updateCategory,
  deleteCategory,
} from "../../store/slices/category/categorySlice";

// Unified validation schema for both categories and grammar rules
const itemValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
});

const settingsValidationSchema = Yup.object().shape({
  OPENAI_API_KEY: Yup.string().required("Please enter Open AI API Key"),
});

// Generic ItemSection component for both categories and grammar rules
const ItemSection = ({
  title,
  items,
  onAdd,
  onEdit,
  onDelete,
  modalOpen,
  setModalOpen,
  selectedItem,
  setSelectedItem,
  handleSubmit,
  formik
}) => {

  return (
    <Box variant="div" component="div" width="50%">
      <Box variant="div" component="div" className="createuser-wrap p-24">
        <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="16px">
          <Typography variant="body1" component="label" className="label">
            {title}
          </Typography>
          <Button
            variant="contained"
            size="large"
            className="primary-btn btn"
            onClick={onAdd}
            disableRipple
          >
            Add {title}
          </Button>
        </Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{fontWeight:900}}>Name</TableCell>
                <TableCell sx={{fontWeight:900}} align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items && items.length > 0 ? (
                items.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell align="right">
                      <IconButton onClick={() => onEdit(item)}>
                        <MdModeEditOutline />
                      </IconButton>
                      <IconButton onClick={() => onDelete(item.id)}>
                        <RiDeleteBin5Fill />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={2}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      padding="20px"
                    >
                      <Typography variant="body1">
                        No {title.toLowerCase()} added yet
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Modal */}
        <Dialog
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          maxWidth="sm"
          fullWidth
        >
          <form onSubmit={handleSubmit}>
            <Box variant="div" component="div" className="createuser-wrap p-24">
              <Typography variant="body1" component="label" className="label" marginBottom="16px">
                {selectedItem ? `Edit ${title}` : `Add New ${title}`}
              </Typography>

              <Box variant="div" component="div" marginBottom="16px">
                <Typography variant="body1" component="label" className="label">
                  Name
                </Typography>
                <TextField
                  className="input-field"
                  fullWidth
                  variant="outlined"
                  size="small"
                  placeholder={`Enter ${title.toLowerCase()} name`}
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={
                    formik.touched.name && formik.errors.name ? (
                      <span className="error-message">
                        {formik.errors.name}
                      </span>
                    ) : null
                  }
                />
              </Box>

              <Box
                variant="div"
                component="div"
                className="footer-btn"
                style={{ marginTop: "16px", height: "35px" }}
              >
                <Box variant="div" component="div" className="btn-group">
                  <Button
                    variant="outlined"
                    size="large"
                    className="secondary-btn btn"
                    onClick={() => setModalOpen(false)}
                    disableRipple
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    className="primary-btn btn"
                    type="submit"
                    disableRipple
                  >
                    {selectedItem ? "Update" : "Create"}
                  </Button>
                </Box>
              </Box>
            </Box>
          </form>
        </Dialog>
      </Box>
    </Box>
  );
};

const Settings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = getToken();

  // States
  const [setting, setSetting] = useState();
  const [categories, setCategories] = useState([]);
  const [grammarRules, setGrammarRules] = useState([]);
  const [categoryModalOpen, setCategoryModalOpen] = useState(false);
  const [grammarModalOpen, setGrammarModalOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedGrammarRule, setSelectedGrammarRule] = useState(null);

  // Settings Formik
  const settingsFormik = useFormik({
    initialValues: {
      OPENAI_API_KEY: "",
    },
    validationSchema: settingsValidationSchema,
    onSubmit: (values) => {
      dispatch(editSetting(values, token))
        .then((response) => {
          if (response.success) {
            successToast("Setting updated successfully");
            fetchSettingsData();
          }
        })
        .catch((error) => {
          console.error("API call error:", error);
        });
    },
  });

  // Category Formik
  const categoryFormik = useFormik({
    initialValues: { name: "" },
    validationSchema: itemValidationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        if (selectedCategory) {
          await dispatch(updateCategory(module = "comprehension", selectedCategory.id, values, token));
          successToast("Category updated successfully");
        } else {
          await dispatch(createCategory(module = "comprehension", values, token));
          successToast("Category created successfully");
        }
        resetForm();
        setCategoryModalOpen(false);
        setSelectedCategory(null);
        fetchCategoriesData();
      } catch (error) {
        errorToast(error.message);
      }
    },
  });

  // Grammar Rule Formik
  const grammarFormik = useFormik({
    initialValues: { name: "" },
    validationSchema: itemValidationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        if (selectedGrammarRule) {
          await dispatch(updateCategory(module = "grammar", selectedGrammarRule.id, values, token));
          successToast("Grammar rule updated successfully");
        } else {
          await dispatch(createCategory(module = "grammar", values, token));
          successToast("Grammar rule created successfully");
        }
        resetForm();
        setGrammarModalOpen(false);
        setSelectedGrammarRule(null);
        fetchGrammarRulesData();
      } catch (error) {
        errorToast(error.message);
      }
    },
  });

  // Fetch Functions
  const fetchSettingsData = async () => {
    try {
      const response = await callApi({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/settings/view`,
      });
      setSetting(response);
    } catch (error) {
      console.error(error);
      errorToast(error.response.data.errors);
      if (error.response?.data?.errors?.includes("You are not authorized to access this resource.")) {
        navigate("*");
      }
    }
  };

  const fetchCategoriesData = async () => {
    try {
      const response = await dispatch(fetchCategories(module = "comprehension", token));
      if (response.success) {
        setCategories(response.data.comprehension_categories);
      }
    } catch (error) {
      errorToast(error.message);
    }
  };

  const fetchGrammarRulesData = async () => {
    try {
      const response = await dispatch(fetchCategories(module = "grammar", token));
      if (response.success) {
        setGrammarRules(response.data.grammar_rule_categories);
      }
    } catch (error) {
      errorToast(error.message);
    }
  };

  // Effects
  useEffect(() => {
    fetchSettingsData();
    fetchCategoriesData();
    fetchGrammarRulesData();
  }, []);

  useEffect(() => {
    settingsFormik.setValues({
      OPENAI_API_KEY: setting?.settings?.OPENAI_API_KEY || "",
    });
  }, [setting]);

  return (
    <Box className="content-layout" marginTop="32px">
      {/* Settings Section */}
      <Box className="content-layout" marginTop="32px">
        <Box variant="div" component="div" className="createuser-wrap p-24">
          <form onSubmit={settingsFormik.handleSubmit}>
            <Grid container spacing={4}>
              <Grid item sm={12} md={12} lg={12}>
                <Box variant="div" component="div" className="user-detail">
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <Box
                        variant="div"
                        component="div"
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 2,
                        }}
                      >
                        <Typography
                          variant="body1"
                          component="label"
                          className="label"
                          sx={{ marginRight: 2, alignItems: 'center' }}
                        >
                          Open AI API Key
                        </Typography>

                        <TextField
                          className="input-field"
                          fullWidth
                          variant="outlined"
                          size="small"
                          placeholder="Enter Open AI API Key"
                          name="OPENAI_API_KEY"
                          value={settingsFormik.values.OPENAI_API_KEY}
                          onChange={settingsFormik.handleChange}
                          onBlur={settingsFormik.handleBlur}
                          error={
                            settingsFormik.touched.OPENAI_API_KEY &&
                            Boolean(settingsFormik.errors.OPENAI_API_KEY)
                          }
                          helperText={
                            settingsFormik.touched.OPENAI_API_KEY &&
                            settingsFormik.errors.OPENAI_API_KEY
                          }
                          sx={{ flex: 1 }}
                        />

                        <Button
                          variant="contained"
                          size="large"
                          className="primary-btn btn"
                          type="submit"
                          disableRipple
                          sx={{ whiteSpace: 'nowrap' }}
                        >
                          Save
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>

      <hr />
      <h1 style={{ margin: "20px" }}>Manage Categories</h1>

      {/* Comprehension and Grammar Rules Sections */}
      <Box variant="div" component="div" display="flex" gap={3}>
        <ItemSection
          title="Comprehension"
          items={categories}
          onAdd={() => {
            categoryFormik.resetForm();
            setCategoryModalOpen(true);
          }}
          onEdit={(category) => {
            setSelectedCategory(category);
            categoryFormik.setValues({ name: category.name });
            setCategoryModalOpen(true);
          }}
          onDelete={async (id) => {
            if (window.confirm("Are you sure you want to delete this category?")) {
              try {
                await dispatch(deleteCategory(module = "comprehension", id, token));
                // successToast("Category deleted successfully");
                fetchCategoriesData();
              } catch (error) {
                errorToast(error.message);
              }
            }
          }}
          modalOpen={categoryModalOpen}
          setModalOpen={setCategoryModalOpen}
          selectedItem={selectedCategory}
          setSelectedItem={setSelectedCategory}
          handleSubmit={categoryFormik.handleSubmit}
          formik={categoryFormik}
        />

        <ItemSection
          title="Grammar Rules"
          items={grammarRules}
          onAdd={() => {
            grammarFormik.resetForm();
            setGrammarModalOpen(true);
          }}
          onEdit={(rule) => {
            setSelectedGrammarRule(rule);
            grammarFormik.setValues({ name: rule.name });
            setGrammarModalOpen(true);
          }}
          onDelete={async (id) => {
            if (window.confirm("Are you sure you want to delete this grammar rule?")) {
              try {
                await dispatch(deleteCategory(module = "grammar", id, token));
                // successToast("Grammar rule deleted successfully");
                fetchGrammarRulesData();
              } catch (error) {
                errorToast(error.message);
              }
            }
          }}
          modalOpen={grammarModalOpen}
          setModalOpen={setGrammarModalOpen}
          selectedItem={selectedGrammarRule}
          setSelectedItem={setSelectedGrammarRule}
          handleSubmit={grammarFormik.handleSubmit}
          formik={grammarFormik}
        />
      </Box>
    </Box>
  );
};

export default Settings;