import { Box, Fade, Modal, Typography } from "@mui/material";
import React from "react";
import ReactMarkdown from "react-markdown";

const ViewComprehensionQuestionModal = ({
  handleQuestionClose,
  open,
  setOpen,
  examDataById,
  selectedQuestion,
}) => {
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleQuestionClose}
        closeAfterTransition
      >
        <Fade in={open}>
          <Box className="question-modal-wrapper">
            <Box variant="div" component="div" className="title">
              {/* <Typography variant="h3" component="h3" className="modal-title">
                {`Are you sure you want to delete this exam ?`}
              </Typography> */}
              <Typography
                variant="body1"
                component="span"
                className="value"
                style={{ whiteSpace: "pre-wrap" }}
                // dangerouslySetInnerHTML={{ __html: selectedQuestion }}
              >
                <ReactMarkdown>{selectedQuestion}</ReactMarkdown>
              </Typography>
            </Box>
            <Box variant="div" component="div" className="body"></Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default ViewComprehensionQuestionModal;
