import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getToken } from "../../../utils/token";
import { errorToast } from "../../../response/errorToast";
import { setLoading } from "../loader/loaderSlice";

// Async thunk to fetch user favourites
export const fetchUserfavourites = () => async (dispatch) => {
  try {
    // dispatch(setLoading(true));
    dispatch(fetchfavouritesStart());
    const token = getToken();

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/users/favourites/list`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    dispatch(fetchfavouritesSuccess(response.data));
  } catch (error) {
    errorToast(error.response?.data?.errors);
    dispatch(fetchfavouritesFailure(error.response?.data?.errors));
  } finally {
    // dispatch(setLoading(false));
  }
};

// Async thunk to add a favorite
export const addTofavourites = (payload) => async (dispatch) => {
  try {
    // dispatch(setLoading(true));
    const token = getToken();

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/users/favourites/add`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    dispatch(addfavouriteSuccess(payload));
    return response.data;
  } catch (error) {
    errorToast(error.response?.data?.errors);
    dispatch(addFavoriteFailure(error.response?.data?.errors));
    throw error;
  } finally {
    // dispatch(setLoading(false));
  }
};

// Async thunk to remove a favorite
export const removeFromfavourites = (payload) => async (dispatch) => {
  try {
    // dispatch(setLoading(true));
    const token = getToken();

    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}/users/favourites/remove`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: payload,
      }
    );

    dispatch(removefavouriteSuccess(payload));
    return response.data;
  } catch (error) {
    errorToast(error.response?.data?.errors);
    dispatch(removeFavoriteFailure(error.response?.data?.errors));
    throw error;
  } finally {
    // dispatch(setLoading(false));
  }
};

// Initial state
const initialState = {
  favourites: {
    favourites: {
      comprehension: [],
      essay: [],
      grammar_rule: [],
    },
  },
  status: "idle",
  error: null,
};

// Create slice
const favouritesSlice = createSlice({
  name: "favourites",
  initialState,
  reducers: {
    // Fetch favourites reducers
    fetchfavouritesStart: (state) => {
      state.status = "loading";
    },
    fetchfavouritesSuccess: (state, action) => {
      state.status = "succeeded";
      state.favourites = action.payload;
    },
    fetchfavouritesFailure: (state, action) => {
      state.status = "failed";
      state.error = Array.isArray(action.payload)
        ? action.payload[0]
        : action.payload?.message;
    },

    // Add favorite reducers
    addfavouriteSuccess: (state, action) => {
      const { module_name, item_id } = action.payload;
      state.status = "succeeded";

      // Ensure the module exists in the state
      if (!state.favourites.favourites[module_name]) {
        state.favourites.favourites[module_name] = [];
      }

      // Check if the item already exists to prevent duplicates
      const existingIndex = state.favourites.favourites[module_name].findIndex(
        (fav) => fav.item_id === item_id
      );

      if (existingIndex === -1) {
        state.favourites.favourites[module_name].push({
          item_id: item_id,
          ...action.payload
        });
      }
    },
    addFavoriteFailure: (state, action) => {
      state.error = Array.isArray(action.payload)
        ? action.payload[0]
        : action.payload?.message;
    },

    // Remove favorite reducers
    removefavouriteSuccess: (state, action) => {
      const { module_name, item_id } = action.payload;

      if (state.favourites?.favourites?.[module_name]) {
        state.favourites.favourites[module_name] = state.favourites.favourites[
          module_name
        ].filter((fav) => fav.item_id !== item_id);
      }
    },
    removeFavoriteFailure: (state, action) => {
      state.error = Array.isArray(action.payload)
        ? action.payload[0]
        : action.payload?.message;
    },
  },
});

// Export actions
export const {
  fetchfavouritesStart,
  fetchfavouritesSuccess,
  fetchfavouritesFailure,
  addfavouriteSuccess,
  addFavoriteFailure,
  removefavouriteSuccess,
  removeFavoriteFailure,
} = favouritesSlice.actions;

export default favouritesSlice.reducer;
