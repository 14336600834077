import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorToast } from "../../../response/errorToast";

export const deleteComprehensionById = (examId, token) => async (dispatch) => {
  try {
    dispatch(deleteComprehensionStart());

    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}/comprehension/delete/${examId}/`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    dispatch(deleteComprehensionSuccess(response.data));
    return { success: true, message: response?.message };
  } catch (error) {
    errorToast(error.response.data.errors);
    dispatch(deleteComprehensionFailure(error.response.data));
  }
};

const initialState = {
  Comprehension: [],
  status: "idle",
  error: null,
};
const deleteComprehensionSlice = createSlice({
  name: "deleteComprehension",
  initialState,
  reducers: {
    deleteComprehensionStart: (state) => {
      state.status = "loading";
    },
    deleteComprehensionSuccess: (state, action) => {
      state.status = "succeeded";
      state.Comprehension = action.payload;
    },
    deleteComprehensionFailure: (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
    },
  },
});

export const {
  deleteComprehensionStart,
  deleteComprehensionSuccess,
  deleteComprehensionFailure,
} = deleteComprehensionSlice.actions;

export default deleteComprehensionSlice.reducer;
