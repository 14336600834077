import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorToast } from "../../../response/errorToast";

const initialState = {
  isLoading: false,
  success: false,
  error: null,
  categories: [],
  selectedCategory: null,
};

const comprehensionCategorySlice = createSlice({
  name: "comprehensionCategory",
  initialState,
  reducers: {
    // Create
    createCategoryStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    createCategorySuccess: (state) => {
      state.isLoading = false;
      state.success = true;
      state.error = null;
    },
    createCategoryFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },

    // List
    fetchCategoriesStart: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    fetchCategoriesSuccess: (state, action) => {
      state.isLoading = false;
      state.categories = action.payload;
      state.error = null;
    },
    fetchCategoriesFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    // Single Category
    fetchCategoryStart: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    fetchCategorySuccess: (state, action) => {
      state.isLoading = false;
      state.selectedCategory = action.payload;
      state.error = null;
    },
    fetchCategoryFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    // Update
    updateCategoryStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    updateCategorySuccess: (state) => {
      state.isLoading = false;
      state.success = true;
      state.error = null;
    },
    updateCategoryFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },

    // Delete
    deleteCategoryStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    deleteCategorySuccess: (state) => {
      state.isLoading = false;
      state.success = true;
      state.error = null;
    },
    deleteCategoryFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
  },
});

export const {
  createCategoryStart,
  createCategorySuccess,
  createCategoryFailure,
  fetchCategoriesStart,
  fetchCategoriesSuccess,
  fetchCategoriesFailure,
  fetchCategoryStart,
  fetchCategorySuccess,
  fetchCategoryFailure,
  updateCategoryStart,
  updateCategorySuccess,
  updateCategoryFailure,
  deleteCategoryStart,
  deleteCategorySuccess,
  deleteCategoryFailure,
} = comprehensionCategorySlice.actions;

// Thunk actions
export const createCategory = (module, categoryData, token) => async (dispatch) => {
  try {
    dispatch(createCategoryStart());

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/category/${module}/create/`,
      categoryData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    dispatch(createCategorySuccess());
    return { success: true, message: response.data.message };
  } catch (error) {
    errorToast(error.response.data.errors);
    dispatch(createCategoryFailure(error.message));
    return { success: false, error: error.response.message };
  }
};

export const fetchCategories = (module, token) => async (dispatch) => {
  try {
    dispatch(fetchCategoriesStart());

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/category/${module}/list`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    dispatch(fetchCategoriesSuccess(response.data));
    return { success: true, data: response.data };
  } catch (error) {
    errorToast(error.response.data.errors);
    dispatch(fetchCategoriesFailure(error.message));
    return { success: false, error: error.response.message };
  }
};

export const fetchCategory = (module, id, token) => async (dispatch) => {
  try {
    dispatch(fetchCategoryStart());

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/category/${module}/${id}/`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    dispatch(fetchCategorySuccess(response.data));
    return { success: true, data: response.data };
  } catch (error) {
    errorToast(error.response.data.errors);
    dispatch(fetchCategoryFailure(error.message));
    return { success: false, error: error.response.message };
  }
};

export const updateCategory = (module, id, categoryData, token) => async (dispatch) => {
  try {
    dispatch(updateCategoryStart());

    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/category/${module}/${id}/update/`,
      categoryData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    dispatch(updateCategorySuccess());
    return { success: true, message: response.data.message };
  } catch (error) {
    errorToast(error.response.data.errors);
    dispatch(updateCategoryFailure(error.message));
    return { success: false, error: error.response.message };
  }
};

export const deleteCategory = (module, id, token) => async (dispatch) => {
  try {
    dispatch(deleteCategoryStart());

    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}/category/${module}/${id}/delete/`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    dispatch(deleteCategorySuccess());
    return { success: true, message: response.data.message };
  } catch (error) {
    errorToast(error.response.data.errors);
    dispatch(deleteCategoryFailure(error.message));
    return { success: false, error: error.response.message };
  }
};

export default comprehensionCategorySlice.reducer;