import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, TextField, Button } from "@mui/material";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { successToast } from "../../../response/successToast";
import { getToken } from "../../../utils/token";
import { editExam } from "../../../store/slices/exams/editExamByIdSlice";
import { IoMdArrowRoundBack } from "react-icons/io";
import * as Yup from "yup";
import { callApi } from "../../../api";
import { errorToast } from "../../../response/errorToast";
import { setLoading } from "../../../store/slices/loader/loaderSlice";
import Loader from "../../../common/loader";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Please enter exam name"),
  exam_prompt: Yup.string().required("Please enter exam prompt"),
  feedback_prompt: Yup.string().required("Please enter feedback prompt"),
  description: Yup.string().required("Please enter description"),
});

const EditExam = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [examDataById, setExamDataById] = useState();
  const token = getToken();
  const { id } = useParams();
  const formik = useFormik({
    initialValues: {
      name: "", // Set initial values from stored credentials
      exam_prompt: "",
      feedback_prompt: "",
      description: "",
 
    },
    validationSchema,
    onSubmit: (values) => {
      dispatch(editExam(id, values, token))
        .then((response) => {
          if (response.success) {
            successToast("Exam updated successfully");
            navigate("/exams");
          } else {
            console.error("Login failed. Please check your credentials.");
          }
        })
        .catch((error) => {
          console.error("API call error:", error);
        });
    },
  });

  const fetchData = async () => {
    try {
      dispatch(setLoading(true));
      const response = await callApi({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/exams/view/${id}/`,
      });
      setExamDataById(response);
    } catch (error) {
      errorToast(error.response.data.errors);
      if (
        error.response &&
        error.response.data &&
        error.response.data.errors &&
        error.response.data.errors.includes("Exam with given id does not exist")
      ) {
        navigate("*");
      }
    } finally {
      dispatch(setLoading(false));
    }
  };

  const { isLoading } = useSelector((state) => state.loader);
  useEffect(() => {
    // if (error) {
    //   navigate("*");
    // }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    formik.setValues({
      name: examDataById?.data?.name || "",
      exam_prompt: examDataById?.data?.exam_prompt || "",
      feedback_prompt: examDataById?.data?.feedback_prompt || "",
      description: examDataById?.data?.description || "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [examDataById]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Box className="content-header-title" style={{ height: "35px" }}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="100%"
            >
              <Button className="action-btn" onClick={() => navigate(`/exams`)}>
                <IoMdArrowRoundBack className="icon-font" />
              </Button>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography variant="h2" component="h2">
                  Edit Exam
                </Typography>
                <Button
                  variant="contained"
                  size="large"
                  className="primary-btn btn add-btn"
                  disableRipple
                  onClick={() =>
                    navigate(`/exams/${examDataById?.data?.id}/instructions`)
                  }
                  // style={{ height: "35px" }}
                >
                  <Typography variant="body1" component="p">
                    Add Information
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Box>
          <Box className="content-layout">
            <Box variant="div" component="div" className="createuser-wrap p-24">
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={4}>
                  <Grid item sm={12} md={12} lg={12}>
                    <Box variant="div" component="div" className="user-detail">
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                          <Box variant="div" component="div">
                            <Typography
                              variant="body1"
                              component="label"
                              className="label"
                            >
                              Name of Exam
                            </Typography>

                            <TextField
                              fullWidth
                              className="input-field"
                              id="outlined-basic"
                              label=""
                              variant="outlined"
                              size="small"
                              placeholder="Enter Exam Name"
                              name="name"
                              value={formik.values.name}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              error={
                                formik.touched.name &&
                                Boolean(formik.errors.name)
                              }
                              helperText={
                                formik.touched.name && formik.errors.name ? (
                                  <span className="error-message">
                                    {formik.errors.name}
                                  </span>
                                ) : null
                              }
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <Box variant="div" component="div">
                            <Typography
                              variant="body1"
                              component="label"
                              className="label"
                            >
                              Exam Prompt
                            </Typography>

                            <TextField
                              multiline
                              rows={5}
                              className="input-field"
                              placeholder="Enter Exam Prompt"
                              name="exam_prompt"
                              value={formik.values.exam_prompt}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              sx={{ width: "100%" }}
                              error={
                                formik.touched.exam_prompt &&
                                Boolean(formik.errors.exam_prompt)
                              }
                              helperText={
                                formik.touched.exam_prompt &&
                                formik.errors.exam_prompt ? (
                                  <span className="error-message">
                                    {formik.errors.exam_prompt}
                                  </span>
                                ) : null
                              }
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <Box variant="div" component="div">
                            <Typography
                              variant="body1"
                              component="label"
                              className="label"
                            >
                              Feedback Prompt
                            </Typography>

                            <TextField
                              multiline
                              rows={5}
                              className="input-field"
                              placeholder="Enter Feedback Prompt"
                              name="feedback_prompt"
                              value={formik.values.feedback_prompt}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              sx={{ width: "100%" }}
                              error={
                                formik.touched.feedback_prompt &&
                                Boolean(formik.errors.feedback_prompt)
                              }
                              helperText={
                                formik.touched.feedback_prompt &&
                                formik.errors.feedback_prompt ? (
                                  <span className="error-message">
                                    {formik.errors.feedback_prompt}
                                  </span>
                                ) : null
                              }
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <Box variant="div" component="div">
                            <Typography
                              variant="body1"
                              component="label"
                              className="label"
                            >
                              Description
                            </Typography>

                            <TextField
                              multiline
                              rows={3}
                              className="input-field"
                              placeholder="Enter Description"
                              name="description"
                              value={formik.values.description}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              sx={{ width: "100%" }}
                              error={
                                formik.touched.description &&
                                Boolean(formik.errors.description)
                              }
                              helperText={
                                formik.touched.description &&
                                formik.errors.description ? (
                                  <span className="error-message">
                                    {formik.errors.description}
                                  </span>
                                ) : null
                              }
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
                <Box
                  variant="div"
                  component="div"
                  className="footer-btn"
                  style={{ marginTop: "16px", height: "35px" }}
                >
                  <Box variant="div" component="div" className="btn-group">
                    <Button
                      variant="contained"
                      size="large"
                      className="primary-btn btn"
                      type="submit"
                      disableRipple
                    >
                      {" "}
                      Save
                    </Button>
                  </Box>
                </Box>
              </form>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};
export default EditExam;
