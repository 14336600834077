import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorToast } from "../../../response/errorToast";

const initialState = {
  isLoading: false,
  success: false,
  error: null,
};

const createComprehensionSlice = createSlice({
  name: "createComprehension",
  initialState,
  reducers: {
    createComprehensionStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    createComprehensionSuccess: (state) => {
      state.isLoading = false;
      state.success = true;
      state.error = null;
    },
    createComprehensionFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
  },
});

export const {
  createComprehensionStart,
  createComprehensionSuccess,
  createComprehensionFailure,
} = createComprehensionSlice.actions;

export default createComprehensionSlice.reducer;

export const createComprehension = (comprehensionData, token) => async (dispatch) => {
  try {
    dispatch(createComprehensionStart());

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/comprehension/create`,
      comprehensionData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = response.data;
    dispatch(createComprehensionSuccess());
    // successToast("Lead creatde successfully!");
    return { success: true, message: data.message };
  } catch (error) {
    errorToast(error.response.data.errors);
    dispatch(createComprehensionFailure(error.message));

    return { success: false, error: error.response.message };
  }
};
