import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  MenuItem
} from "@mui/material";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { successToast } from "../../../response/successToast";
import { getToken } from "../../../utils/token";
import { IoMdArrowRoundBack } from "react-icons/io";
import * as Yup from "yup";
import { callApi } from "../../../api";
import { createGrammar } from "../../../store/slices/grammar/createGrammarSlice";
import { errorToast } from "../../../response/errorToast";
import { fetchCategories } from "../../../store/slices/category/categorySlice";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Please enter grammar rule name"),
  explanation_prompt: Yup.string().required("Please enter explanation prompt"),
  exam_prompt: Yup.string().required("Please enter exam prompt"),
  feedback_prompt: Yup.string().required("Please enter feedback prompt"),
  question_count: Yup.number().required("Please enter question count"),
  // question_type: Yup.string().required("Please select a question type"),
  category: Yup.string().required("Please Select a category"),
});

const AddGrammar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //   const grammar = useSelector((state) => state.settings.settings);
  const [grammar, setGrammar] = useState();
  const [categories, setCategories] = useState([]);
  //   const error = useSelector((state) => state.settings.error);
  const token = getToken();
  const formik = useFormik({
    initialValues: {
      name: "",
      explanation_prompt: "",
      exam_prompt: "",
      feedback_prompt: "",
      question_type: "mcq",
      question_count: "",
      category: ""
    },
    validationSchema,
    onSubmit: (values) => {
      dispatch(createGrammar(values, token))
        .then((response) => {
          if (response.success) {
            successToast("Grammar created successfully");
            navigate("/grammar");

            // If "Remember Me" is checked, store the credentials
          } else {
            console.error("Login failed. Please check your credentials.");
          }
        })
        .catch((error) => {
          console.error("API call error:", error);
        });
    },
  });

  const fetchData = async () => {
    try {
      const response = await callApi({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/settings/view`,
      });
      setGrammar(response);
    } catch (error) {
      console.error(error);
      errorToast(error.response.data.errors);
      // if (error?.message === "You have no permission to view this leave.") {
      //   navigate("/noaccess");
      // } else if (error?.message === "Leave not found") {
      //   navigate("*");
      // }
    }
  };
  const fetchGrammarRulesData = async () => {
    try {
      const response = await dispatch(fetchCategories(module = "grammar", token));
      if (response.success) {
        setCategories(response.data.grammar_rule_categories || []);
      }
    } catch (error) {
      errorToast(error.message);
    }
  };

  useEffect(() => {
    fetchData();
    fetchGrammarRulesData();
  }, [dispatch]);

  useEffect(() => {
    formik.setValues({
      name: "",
      explanation_prompt:
        grammar?.settings?.GRAMMAR_RULE_EXPLANATION_PROMPT || "",
      exam_prompt: grammar?.settings?.GRAMMAR_RULE_PRACTICE_PROMPT || "",
      feedback_prompt: grammar?.settings?.GRAMMAR_RULE_FEEDBACK_PROMPT || "",
      question_type: "mcq",
      question_count: "",
      category: ""
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grammar]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    // Set different prompts based on the selected question type
    const examPrompt =
      value === "mcq"
        ? grammar?.settings?.GRAMMAR_RULE_PRACTICE_PROMPT || ""
        : grammar?.settings?.GRAMMAR_RULE_SHORT_PRACTICE_PROMPT || "";

    const feedbackPrompt =
      value === "mcq"
        ? grammar?.settings?.GRAMMAR_RULE_FEEDBACK_PROMPT
        : grammar?.settings?.GRAMMAR_RULE_SHORT_FEEDBACK_PROMPT;

    // Update formik values
    formik.setValues({
      ...formik.values,
      [name]: value,
      exam_prompt: examPrompt,
      feedback_prompt: feedbackPrompt,
    });
  };

  return (
    <>
      <Box className="content-header-title">
        <Button className="action-btn" onClick={() => navigate(`/grammar`)}>
          <IoMdArrowRoundBack className="icon-font" />
        </Button>
        <Typography variant="h2" component="h2">
          Create Grammar Rule
        </Typography>
      </Box>
      <Box className="content-layout">
        <Box variant="div" component="div" className="createuser-wrap p-24">
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={4}>
              <Grid item sm={12} md={12} lg={12}>
                <Box variant="div" component="div" className="user-detail">
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <Box display="flex" justifyContent="space-between" alignItems="center" gap="16px">
                        {/* Grammar Rule Name Input */}
                        <Box flex={1}>
                          <Typography
                            variant="body1"
                            component="label"
                            className="label"
                          >
                            Grammar Rule Name
                          </Typography>
                          <TextField
                            className="input-field"
                            fullWidth
                            id="outlined-basic"
                            variant="outlined"
                            size="small"
                            placeholder="Enter Grammar Rule Name"
                            name="name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={
                              formik.touched.name && formik.errors.name ? (
                                <span className="error-message">
                                  {formik.errors.name}
                                </span>
                              ) : null
                            }
                          />
                        </Box>

                        {/* Grammar Rule Category Dropdown */}
                        <Box flex={1}>
                          <Typography
                            variant="body1"
                            component="label"
                            className="label"
                          >
                            Grammar Rule Category
                          </Typography>
                          <TextField
                            select
                            className="input-field"
                            fullWidth
                            id="category"
                            variant="outlined"
                            size="small"
                            placeholder="Select Grammar Rule Category"
                            name="category"
                            value={formik.values.category}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.category && Boolean(formik.errors.category)}
                            helperText={
                              formik.touched.category && formik.errors.category ? (
                                <span className="error-message">
                                  {formik.errors.category}
                                </span>
                              ) : null
                            }
                          >
                            {categories.length > 0 ? (
                              categories.map((category) => (
                                <MenuItem key={category.id} value={category.id}>
                                  {category.name}
                                </MenuItem>
                              ))
                            ) : (
                              <MenuItem disabled>
                                No categories available.
                              </MenuItem>
                            )}
                            {categories.length === 0 && (
                              <MenuItem>
                                <Button
                                  variant="text"
                                  color="primary"
                                  onClick={() => navigate('/settings')}
                                >
                                  Add Categories
                                </Button>
                              </MenuItem>
                            )}
                          </TextField>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Box variant="div" component="div">
                        <Typography
                          variant="body1"
                          component="label"
                          className="label"
                        >
                          Question Type
                        </Typography>
                        <RadioGroup
                          aria-label="question"
                          name="question_type"
                          value={formik.values.question_type || "mcq"}
                          onChange={handleChange}
                          onBlur={formik.handleBlur}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <FormControlLabel
                            value="mcq"
                            control={<Radio />}
                            label="Multiple Choice Questions"
                          />
                          <FormControlLabel
                            value="short_answers"
                            control={<Radio />}
                            label="Short Answers"
                          />
                          {/* Add more FormControlLabel components for each option */}
                        </RadioGroup>
                        {formik.touched.question_type &&
                          formik.errors.question_type ? (
                          <div className="error-message">
                            {formik.errors.question_type}
                          </div>
                        ) : null}
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Box variant="div" component="div">
                        <Typography
                          variant="body1"
                          component="label"
                          className="label"
                        >
                          Question Count
                        </Typography>
                        <TextField
                          className="input-field"
                          fullWidth
                          id="outlined-basic"
                          label=""
                          variant="outlined"
                          size="small"
                          placeholder="Enter Question Count"
                          name="question_count"
                          type="number"
                          value={formik.values.question_count}
                          onChange={(event) => {
                            const newValue = parseInt(event.target.value);
                            if (!isNaN(newValue) && newValue >= 1) {
                              formik.handleChange(event);
                            } else {
                              // Value is negative or not a number
                              // You can set it to zero or display an error message
                              formik.setFieldValue("question_count", 1); // Set it to zero
                              // OR
                              // formik.setFieldError('question_count', 'Question count cannot be negative'); // Display an error message
                            }
                          }}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.question_count &&
                            Boolean(formik.errors.question_count)
                          }
                          helperText={
                            formik.touched.question_count &&
                              formik.errors.question_count ? (
                              <span
                                classquestion_count="error-message"
                                style={{
                                  color: "#ee5e5e",
                                  marginLeft: "-10px",
                                }}
                              >
                                {formik.errors.question_count}
                              </span>
                            ) : null
                          }
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Box variant="div" component="div">
                        <Typography
                          variant="body1"
                          component="label"
                          className="label"
                        >
                          Explanation Prompt
                        </Typography>

                        <TextField
                          multiline
                          rows={5}
                          className="input-field"
                          placeholder="Enter Explanation Prompt"
                          name="explanation_prompt"
                          value={formik.values.explanation_prompt}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          sx={{ width: "100%" }}
                          error={
                            formik.touched.explanation_prompt &&
                            Boolean(formik.errors.explanation_prompt)
                          }
                          helperText={
                            formik.touched.explanation_prompt &&
                              formik.errors.explanation_prompt ? (
                              <span className="error-message">
                                {formik.errors.explanation_prompt}
                              </span>
                            ) : null
                          }
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Box variant="div" component="div">
                        <Typography
                          variant="body1"
                          component="label"
                          className="label"
                        >
                          Exam Prompt
                        </Typography>

                        <TextField
                          multiline
                          rows={5}
                          className="input-field"
                          placeholder="Enter Exam Prompt"
                          name="exam_prompt"
                          value={formik.values.exam_prompt}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          sx={{ width: "100%" }}
                          error={
                            formik.touched.exam_prompt &&
                            Boolean(formik.errors.exam_prompt)
                          }
                          helperText={
                            formik.touched.exam_prompt &&
                              formik.errors.exam_prompt ? (
                              <span className="error-message">
                                {formik.errors.exam_prompt}
                              </span>
                            ) : null
                          }
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Box variant="div" component="div">
                        <Typography
                          variant="body1"
                          component="label"
                          className="label"
                        >
                          Feedback Prompt
                        </Typography>

                        <TextField
                          multiline
                          rows={5}
                          className="input-field"
                          placeholder="Enter Feedback Prompt"
                          name="feedback_prompt"
                          value={formik.values.feedback_prompt}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          sx={{ width: "100%" }}
                          error={
                            formik.touched.feedback_prompt &&
                            Boolean(formik.errors.feedback_prompt)
                          }
                          helperText={
                            formik.touched.feedback_prompt &&
                              formik.errors.feedback_prompt ? (
                              <span className="error-message">
                                {formik.errors.feedback_prompt}
                              </span>
                            ) : null
                          }
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            <Box
              variant="div"
              component="div"
              className="footer-btn"
              style={{ marginTop: "16px", height: "35px" }}
            >
              <Box variant="div" component="div" className="btn-group">
                <Button
                  variant="contained"
                  size="large"
                  className="primary-btn btn"
                  type="submit"
                  disableRipple
                >
                  {" "}
                  Save
                </Button>
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  );
};
export default AddGrammar;
