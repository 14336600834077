import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, Button, CircularProgress } from "@mui/material";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import { callApi } from "../../../api";
import { errorToast } from "../../../response/errorToast";
import ReactMarkdown from "react-markdown";
import moment from "moment";
import { attemptTryAgainExam } from "../../../store/slices/exams/attemptExamSlice";
import { getToken } from "../../../utils/token";
import { setFeedbackReady } from "../../../store/slices/feedback/feedbackSlice";

const ResponseFeedback = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const examDataById = useSelector((state) => state.examById.exam);
  const [examDataById, setExamDataById] = useState();
  const userState = useSelector((state) => state.userById.user);
  // eslint-disable-next-line no-unused-vars
  const [feedbackDone, setFeedbackDone] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();

  const formik = useFormik({
    initialValues: {
      name: "",
      question: "", // Set initial values from stored credentials
      response: "",
      feedback: "",
      marks: "",
    },
  });

  const fetchData = async () => {
    try {
      const response = await callApi({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/exam-attempt/view/${id}/`,
      });
      setExamDataById(response);
      if (response?.attempt?.feedback_status === "done") {
        setFeedbackDone(true);
        setIsLoading(false);
        dispatch(
          setFeedbackReady({ url: `/exams/${response?.attempt?.id}/exam-feedback`, message: 'Essay Exam Feedback is Generated' })
        );
      } else {
        setTimeout(fetchData, 3000); // Call fetchData again after 3 seconds
      }
    } catch (error) {
      // console.log(error);
      errorToast(error?.response?.data.errors);
      if (
        error.response &&
        error.response.data &&
        error.response.data.errors &&
        error.response.data.errors.includes("Exam with given id does not exist")
      ) {
        navigate("*");
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  useEffect(() => {
    // Safely parse feedback and handle errors
    let parsedFeedback = {};
    try {
      if (examDataById?.feedback) {
        parsedFeedback = JSON.parse(examDataById.feedback);
      }
    } catch (error) {
      console.error("Error parsing feedback JSON:", error.message);
    }

    // Update Formik values
    formik.setValues({
      question: examDataById?.attempt?.question || "",
      response: examDataById?.attempt?.response || "",
      feedback: parsedFeedback,
      marks: examDataById?.marks || "",
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [examDataById]);

  const checkHasExamAccess = () => {
    if (userState?.essay_practice_left === 0) {
      navigate("/exams");
    }
  };

  return (
    <>
      <Box className="content-header-title">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          <Button
            className="action-btn"
            onClick={() => {
              checkHasExamAccess();
              navigate(`/exams/${examDataById?.attempt?.exam_id}/view`);
            }}
            startIcon={<IoMdArrowRoundBack className="icon-font" />}
          ></Button>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Typography variant="h2" component="h2">
              View Practice Attempted&nbsp;(
              {moment(examDataById?.attempt?.created_at).format("MM-DD-YYYY")})
            </Typography>
            {formik.values.marks && (
              <Typography variant="h2" component="h2" mar>
                Marks: {formik.values.marks}
              </Typography>
            )}
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              {examDataById?.marks === 0 && (
                <Typography variant="h2" component="h2" mar>
                  Marks: 0
                </Typography>
              )}
              <Button
                variant="contained"
                size="large"
                className="primary-btn btn add-btn"
                disableRipple
                onClick={() => {
                  checkHasExamAccess();
                  navigate(`/exams/${examDataById?.attempt?.exam_id}/attempt`);
                }}
              >
                <Typography variant="body1" component="p">
                  Try Again
                </Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className="content-layout">
        <Box variant="div" component="div" className="createuser-wrap p-24">
          <Grid container spacing={4}>
            <Grid item sm={12} md={12} lg={12}>
              {isLoading ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  flexDirection="column"
                  alignItems="center"
                  mt={2}
                >
                  <Typography
                    variant="body2"
                    component="span"
                    className="value"
                    style={{ fontWeight: "600" }}
                  >
                    Give Openessayme a moment to review, will provide you a
                    feedback shortly.....
                  </Typography>
                  <CircularProgress style={{ marginTop: "10px" }} />
                  <Typography
                    variant="body2"
                    component="span"
                    className="value"
                    style={{ fontWeight: "600" }}
                  >
                    You will be notified when the Exam feedback is generated meanwhile you can practice with other modules.
                  </Typography>
                </Box>
              ) : (
                <Box
                  variant="div"
                  component="div"
                  className="user-detail attempt-exam"
                >
                  <Grid container spacing={2}>


                    <Grid item xs={12} md={12}>
                      <Box variant="div" component="div">
                        <Typography
                          variant="body1"
                          component="label"
                          className="label"
                        >
                          Question
                        </Typography>
                        <Typography
                          variant="body2"
                          component="span"
                          className="value"
                        >
                          <ReactMarkdown>
                            {formik.values.question}
                          </ReactMarkdown>
                        </Typography>
                      </Box>
                    </Grid>
                    {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Divider className="divider" />
                  </Grid> */}
                    <Grid item xs={12} md={12} mt={2}>
                      <Box variant="div" component="div">
                        <Typography
                          variant="body1"
                          component="label"
                          className="label"
                        >
                          Response entered by you
                        </Typography>

                        <Typography
                          variant="body2"
                          component="span"
                          className="value"
                          sx={{ marginTop: '10px' }}
                        >
                          <ReactMarkdown>
                            {formik.values.response}
                          </ReactMarkdown>
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={12} mt={2}>
                      <Box variant="div" component="div">
                        <Typography
                          variant="body1"
                          component="label"
                          className="label"
                        >
                          Feedback
                        </Typography>
                        <div
                          style={{
                            marginTop: '10px'
                          }}
                        >
                          <Typography
                            variant="body2"
                            component="span"
                            className="value"
                            sx={{ marginTop: '10px !important' }}
                          >
                            {Object.entries(formik.values.feedback).map(([key, value], index) => {
                              return (
                                <div key={index} style={{ marginBottom: '15px' }}>
                                  <strong>{key} :</strong>
                                  {
                                    (key.toLowerCase() !== "college application essay score" && key.toLowerCase() !== "openessayme equivalent percentage") ? <br /> : " "
                                  }

                                  {typeof value === "object" && value !== null ?

                                    (
                                      Array.isArray(value) ? value.join(", ") : (
                                        // Recursively render nested objects
                                        <div style={{ marginLeft: "1em", marginTop: '5px' }}>
                                          {Object.entries(value).map(([nestedKey, nestedValue], nestedIndex) => (
                                            <li
                                              key={nestedIndex}
                                              style={{
                                                marginBottom: '5px',
                                                display: 'flex',
                                                alignItems: 'flex-start',
                                              }}
                                            >
                                              <strong style={{ marginRight: '8px', whiteSpace: 'nowrap' }}>
                                                {nestedKey}:
                                              </strong>
                                              {Array.isArray(nestedValue) ? nestedValue.join(", ")
                                                :
                                                <span style={{ flex: 1, wordBreak: 'break-word' }}>
                                                  {typeof nestedValue === 'object' && nestedValue !== null
                                                    ? JSON.stringify(nestedValue)
                                                    : nestedValue}
                                                </span>}
                                            </li>
                                          ))}

                                        </div>
                                      )
                                    ) : (
                                      
                                        value + (key === 'OpenEssayMe Equivalent Percentage' ? '%' : '')
                                      
                                    )

                                  }
                                </div>
                              )
                            }
                            )}
                          </Typography>
                        </div>

                      </Box>
                    </Grid>

                  </Grid>
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};
export default ResponseFeedback;
