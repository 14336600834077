import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getToken } from "../../../utils/token";
// import { errorToast } from "../../../response/errorToast";
import { setLoading } from "../loader/loaderSlice";

export const fetchGrammar = (tokenFromParam) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    dispatch(fetchGrammarStart());
    const token = getToken() || tokenFromParam;
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/grammar/list`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = response.data;

    dispatch(fetchGrammarSuccess(response.data));
    return { success: true, message: data.message, data: data };
  } catch (error) {
    // errorToast(error.response.data.errors);
    dispatch(fetchGrammarFailure(error?.response?.data));
  } finally {
    dispatch(setLoading(false));
  }
};

const initialState = {
  grammar: [],
  status: "idle",
  error: null,
};

const grammarSlice = createSlice({
  name: "grammar",
  initialState,
  reducers: {
    fetchGrammarStart: (state) => {
      state.status = "loading";
    },
    fetchGrammarSuccess: (state, action) => {
      state.status = "succeeded";
      state.grammar = action.payload;
    },
    fetchGrammarFailure: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
  },
});

export const { fetchGrammarStart, fetchGrammarSuccess, fetchGrammarFailure } =
  grammarSlice.actions;

export default grammarSlice.reducer;
