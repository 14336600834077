import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorToast } from "../../../response/errorToast";
import { setLoading } from "../loader/loaderSlice";

const initialState = {
  comprehension: null,
  status: "idle",
  error: null,
};

const comprehensionByIdSlice = createSlice({
  name: "comprehensionById",
  initialState,
  reducers: {
    fetchComprehensionByIdStart: (state) => {
      state.status = "loading";
    },
    fetchComprehensionByIdSuccess: (state, action) => {
      state.status = "succeeded";
      state.comprehension = action.payload.data;
    },
    fetchComprehensionByIdFail: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
  },
});

export const {
  fetchComprehensionByIdStart,
  fetchComprehensionByIdSuccess,
  fetchComprehensionByIdFail,
} = comprehensionByIdSlice.actions;

export default comprehensionByIdSlice.reducer;

export const fetchComprehensionById = (id, token) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    dispatch(fetchComprehensionByIdStart());

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/comprehension/view/${id}/`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    dispatch(fetchComprehensionByIdSuccess(response.data));
  } catch (error) {
    errorToast(error.response.data.errors);
    dispatch(fetchComprehensionByIdFail(error.data));
  } finally {
    dispatch(setLoading(false));
  }
};
