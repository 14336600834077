import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorToast } from "../../../response/errorToast";

const initialState = {
  onEditExam: [],
  status: "idle",
  error: null,
};

const editExamSlice = createSlice({
  name: "editExam",
  initialState,
  reducers: {
    updateExamsStart: (state) => {
      state.status = "loading";
    },
    updateExamsSuccess: (state, action) => {
      state.status = "succeeded";
      state.onEditExam = action.payload;
    },
    updateExamsFailure: (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
    },
  },
});

export const { updateExamsStart, updateExamsSuccess, updateExamsFailure } =
  editExamSlice.actions;

export const editExam = (id, examData, token) => async (dispatch) => {
  try {
    // dispatch(setLoading(true));
    dispatch(updateExamsStart());
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/exams/update/${id}`,
      examData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    dispatch(updateExamsSuccess(response));
    return { success: true, message: response?.message };
  } catch (error) {
    errorToast(error.response.data.errors);
    dispatch(updateExamsFailure(error.response.data));
  } finally {
    // dispatch(setLoading(false));
  }
};

export default editExamSlice.reducer;
