import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../utils/token";
import { RiArrowDownSLine } from "react-icons/ri";
import { fetchExams } from "../../store/slices/exams/examSlice";
import { fetchGrammar } from "../../store/slices/grammar/grammarSlice";
import { createConversation } from "../../store/slices/interaction/createConversationSlice";
import { fetchConversations } from "../../store/slices/interaction/conversationSlice";
import MessageModal from "./messageModal";
import Loader from "../../common/loader";
import { fetchComprehension } from "../../store/slices/comprehension/comprehensionSlice";

const AdminAiSupport = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = getToken();
  const [type, setType] = useState("");
  const [examId, setExamId] = useState("");
  const [grammarId, setGrammarId] = useState("");
  const [comprehensionId, setComprehensionId] = useState("");
  const [submit, setSubmit] = useState(false);
  const exams = useSelector((state) => state.exams.exams);
  const grammar = useSelector((state) => state.grammar.grammar);
  const comprehension = useSelector(
    (state) => state.comprehension.comprehension
  );
  const [open, setOpen] = useState(false);
  const conversations = useSelector(
    (state) => state.conversations.conversations
  );

  const { isLoading } = useSelector((state) => state.loader);

  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  const handleExamChange = (event) => {
    setExamId(event.target.value);
    if (type === "exam") {
      setSubmit(true);
    } else {
      setSubmit(false);
    }
  };

  const handleGrammarChange = (event) => {
    setGrammarId(event.target.value);
    if (type === "grammar_rule") {
      setSubmit(true);
    } else {
      setSubmit(false);
    }
  };

  const handleComprehensionChange = (event) => {
    setComprehensionId(event.target.value);
    if (type === "comprehension") {
      setSubmit(true);
    } else {
      setSubmit(false);
    }
  };


  useEffect(() => {
    dispatch(fetchExams({}));
    dispatch(fetchGrammar({}));
    dispatch(fetchComprehension({}));
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchConversations({}));
  }, [dispatch]);
  // console.log(subscription, isCheckingSubscription, isSubscribed, "------");
  useEffect(() => {
    if (submit) {
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit]);

  const handleSubmit = () => {
    const exam = {
      type: type,
      exam_id: examId,
    };

    const grammar = {
      type: type,
      grammar_rule_id: grammarId,
    };

    const comprehension = {
      type: type,
      comprehension_id: comprehensionId,
    };


    let payload;
    if (type === "exam") {
      payload = exam;
    } else if (type === "grammar_rule") {
      payload = grammar;
    } else if (type === "comprehension") {
      payload = comprehension;
    }

    dispatch(createConversation(payload, token))
      .then((response) => {
        if (response.success) {
          setType("");
          setExamId("");
          setGrammarId("");
          setComprehensionId("");
          navigate(
            `/chatbot/my-conversation/${response?.data?.conversation?.uuid}`
          );
          setSubmit(false);
        } else {
          console.error("Error creating conversation:", response.error);
          setSubmit(false);
        }
      })
      .catch((error) => {
        setSubmit(false);
        console.error("Error creating conversation:", error.message);
      });
  };

  const handleDeleteClose = () => {
    setOpen(false);
  };

  if (isLoading) {
    return <Loader />;
  }



  return (
    <>
      <>
        <Box className="content-header-title">
          <Typography variant="h2" component="h2">
            Choose Where You Need Assistance
          </Typography>
        </Box>
        <Box className="content-layout">
          <Box variant="div" component="div" className="createuser-wrap p-24">
            <Grid container spacing={4}>
              <Grid item sm={12} md={12} lg={12}>
                <Box variant="div" component="div" className="user-detail">
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <Box variant="div" component="div">
                        <Typography
                          variant="body1"
                          component="label"
                          className="label"
                        >
                          Select your Domain
                        </Typography>

                        <FormControl fullWidth>
                          <Select
                            id="demo-simple-select"
                            value={type}
                            onChange={handleTypeChange}
                            size="small"
                            IconComponent={RiArrowDownSLine}
                            displayEmpty
                            renderValue={(selected) => {
                              if (!selected) {
                                return (
                                  <span style={{ color: "#aaa" }}>
                                    Select Type
                                  </span>
                                ); // Placeholder styling
                              }
                              return selected === "exam"
                                ? "Essay Practice"
                                : selected === "grammar_rule"
                                  ? "Grammar Rules"
                                  : "Comprehension";
                            }}
                          >
                            <MenuItem value={"exam"}>Essay Practice</MenuItem>
                            <MenuItem value={"grammar_rule"}>
                              Grammar Rules
                            </MenuItem>
                            <MenuItem value={"comprehension"}>
                              Comprehension
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    </Grid>
                    {type === "exam" ? (
                      <Grid item xs={12} md={12}>
                        <Box variant="div" component="div">
                          <Typography
                            variant="body1"
                            component="label"
                            className="label"
                          >
                            Select Exam
                          </Typography>

                          <FormControl fullWidth>
                            <Select
                              value={examId}
                              onChange={handleExamChange}
                              id="demo-simple-select"
                              size="small"
                              IconComponent={RiArrowDownSLine}
                            >
                              {exams?.exams?.map((designation) => (
                                <MenuItem
                                  key={designation.id}
                                  value={designation.id}
                                >
                                  {designation.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                      </Grid>
                    ) : null}

                    {type === "grammar_rule" ? (
                      <Grid item xs={12} md={12}>
                        <Box variant="div" component="div">
                          <Typography
                            variant="body1"
                            component="label"
                            className="label"
                          >
                            Select Grammar Rule
                          </Typography>

                          <FormControl
                            fullWidth
                            style={{ maxHeight: "200px", overflowY: "auto" }}
                          >
                            <Select
                              value={grammarId}
                              onChange={handleGrammarChange}
                              id="demo-simple-select"
                              size="small"
                              IconComponent={RiArrowDownSLine}
                            >
                              {grammar?.grammar_rules?.map((designation) => (
                                <MenuItem
                                  key={designation.id}
                                  value={designation.id}
                                >
                                  {designation.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                      </Grid>
                    ) : null}

                    {type === "comprehension" ? (
                      <Grid item xs={12} md={12}>
                        <Box variant="div" component="div">
                          <Typography
                            variant="body1"
                            component="label"
                            className="label"
                          >
                            Select Comprehension
                          </Typography>

                          <FormControl
                            fullWidth
                            style={{ maxHeight: "200px", overflowY: "auto" }}
                          >
                            <Select
                              value={comprehensionId}
                              onChange={handleComprehensionChange}
                              id="demo-simple-select"
                              size="small"
                              IconComponent={RiArrowDownSLine}
                            >
                              {comprehension?.comprehensions?.map((c) => (
                                <MenuItem key={c.id} value={c.id}>
                                  {c.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                      </Grid>
                    ) : null}
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </>

      {!isLoading && conversations?.conversations?.length > 0 && (
        <Box className="content-layout">
          <TableContainer className="table-listing">
            <Table
              // sx={{ minWidth: 650 }}
              aria-label="table"
              className="listing-table"
            >
              <TableHead>
                <TableRow className="head-row">
                  <TableCell align="left">Previous Chats</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {conversations?.conversations?.map((exam, index) => (
                  <TableRow
                    key={exam.id}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      navigate(
                        `/chatbot/my-conversation/${exam?.uuid}`,
                        "_blank"
                      )
                    }
                  >
                    <TableCell align="left" key={exam?.id} colSpan={2}>
                      {exam?.chat_name}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
      <MessageModal
        open={open}
        setOpen={setOpen}
        handleDeleteClose={handleDeleteClose}
      />
    </>
  );
};

export default AdminAiSupport;
