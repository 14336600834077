import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { capitalizeMessage } from "../utils/helper";

export const errorToast = (errors) => {
  errors?.forEach((error) => {
    toast.error(capitalizeMessage(error), {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      className: "toast",
    });
  });
};
