import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import { callApi } from "../../../api";
import { capitalizeMessage } from "../../../utils/helper";

const ViewGramRuleQues = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [grammar, setGrammar] = useState(null);
  const [questionCompletion, setQuestionCompletion] = useState({}); // State to track question completion
  const [rule, setRule] = useState();
  const [practiceId, setPracticeId] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await callApi({
          method: "get",
          url: `${process.env.REACT_APP_API_URL}/grammar/practice-set/${id}/`,
        });
        setGrammar(response);
        // Initialize question completion status
        const completion = {};
        response?.data.practice_set?.practice_questions?.forEach((question) => {
          completion[question.id] = false;
        });
        setQuestionCompletion(completion);
      } catch (error) {
        console.error(error);
        // Handle error and navigation here
      }
    };

    fetchData();
  }, [id]);

  // Check if all questions are answered
  useEffect(() => {
    const isAllQuestionsAnswered = Object.values(questionCompletion).every(
      (completion) => completion
    );
    // Enable or disable button based on completion status
    const submitButton = document.getElementById("submit-btn");
    if (submitButton) {
      submitButton.disabled = !isAllQuestionsAnswered;
    }
  }, [questionCompletion]);

  useEffect(() => {
    if (grammar) {
      setRule(grammar?.data?.practice_set?.grammar_rule_id);
      setPracticeId(grammar?.data?.practice_set?.id);
    }
  }, [grammar]);

  return (
    <>
      <Box className="content-header-title">
        <Button
          className="action-btn"
          onClick={() =>
            navigate(`/grammar/${grammar?.data?.grammar_rule_id}/view`)
          }
        >
          <IoMdArrowRoundBack className="icon-font" />
        </Button>
        <Typography variant="h2" component="h2">
          {capitalizeMessage(grammar?.data?.grammar_rule_name)}&nbsp;Practice
          Set
        </Typography>
      </Box>
      {grammar?.data?.question_type === "mcq" ? (
        <Box className="content-layout">
          <Box variant="div" component="div" className="createuser-wrap p-24">
            <Grid container spacing={4}>
              <Grid item sm={12} md={12} lg={12}>
                <Box variant="div" component="div" className="user-detail">
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      {(grammar?.data?.paragraph &&
                        (grammar?.data?.paragraph !== "None" && grammar?.data?.paragraph !== "none" && grammar?.data?.paragraph !== "-" && grammar?.data?.paragraph !== "")
                      ) && (
                          <Typography
                            variant="body1"
                            component="label"
                            className="label"
                            mb={4}
                          >
                            Passage: <br />
                            {grammar?.data?.paragraph}
                          </Typography>
                        )}
                      {grammar?.data?.practice_questions?.map((question) => (
                        <Box
                          key={question.id}
                          variant="div"
                          component="div"
                          mb={3}
                        >
                          <Typography
                            variant="body1"
                            component="label"
                            className="label"
                          >
                            {question.id}) {question?.question}
                          </Typography>
                          <ol style={{ marginLeft: "5px" }}>
                            {question?.options?.map((option, index) => (
                              <li
                                key={option.id}
                                style={{
                                  marginBottom: "5px",
                                  marginTop: "5px",
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  component="span"
                                  className="value"
                                // style={{ marginTop: "10px" }}
                                >
                                  {index + 1}. {option?.text}
                                </Typography>
                              </li>
                            ))}
                          </ol>
                          <Box
                            display="flex"
                            // justifyContent="center"
                            alignItems="center"
                            gap="10px"
                          >
                            <Typography
                              variant="body1"
                              component="label"
                              className="label"
                            >
                              Correct Option:
                            </Typography>
                            <Typography
                              variant="body2"
                              component="span"
                              className="value"
                            // style={{ marginTop: "10px" }}
                            >
                              {question?.correct_option}
                            </Typography>
                          </Box>
                        </Box>
                      ))}
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      ) : (
        <Box className="content-layout">
          <Box variant="div" component="div" className="createuser-wrap p-24">
            <Grid container spacing={4}>
              <Grid item sm={12} md={12} lg={12}>
                <Box variant="div" component="div" className="user-detail">
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      {(grammar?.data?.paragraph &&
                        (grammar?.data?.paragraph !== "None" && grammar?.data?.paragraph !== "none" && grammar?.data?.paragraph !== "-" && grammar?.data?.paragraph !== "")
                      ) &&
                        (
                          <Typography
                            variant="body1"
                            component="label"
                            className="label"
                            mb={4}
                          >
                            Passage: <br />
                            {grammar?.data?.paragraph}
                          </Typography>
                        )}
                      {grammar?.data?.practice_questions?.map((question) => (
                        <>
                          <Box
                            key={question.id}
                            variant="div"
                            component="div"
                            mb={3}
                          >
                            <Typography
                              variant="body1"
                              component="label"
                              className="label"
                              style={{ marginBottom: "5px" }}
                            >
                              {question?.id}) {question?.question}
                            </Typography>
                            {/* <Typography
                              variant="body2"
                              component="span"
                              className="value"
                              style={{ marginLeft: "15px" }}
                            >
                              {question?.correct_answer}
                            </Typography> */}
                          </Box>
                        </>
                      ))}
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
    </>
  );
};

export default ViewGramRuleQues;
