import {
  Box,
  Grid,
  Typography,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  TableRow,
  Button,
  LinearProgress,
  styled,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { fetchUserDashboard } from "../../store/slices/dashBoardSlice";
import { fetchUserfavourites } from "../../store/slices/favourite/favouriteSlice";
import { useDispatch, useSelector } from "react-redux";
import getuserData from "../../utils/userData";
import { useNavigate } from "react-router-dom";
import FirstLoginOnboarding from "./onBoarding";
import { fetchGrammar } from "../../store/slices/grammar/grammarSlice";
import { fetchComprehension } from "../../store/slices/comprehension/comprehensionSlice";
import Cookies from "js-cookie";
import { getToken } from "../../utils/token";
import { fetchUserById } from "../../store/slices/users/userByIdSlice";
import MainProgressBar from "../../common/progressbar";
import { Progress } from "rsuite";
// const isAdmin = getuserData()?.isAdmin;

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dashboardData = useSelector((state) => state.userDashboard.dashboard);
  const userFavouritesData = useSelector((state) => state.favourite.favourites);
  const state = useSelector((state) => state);

  const [showFirstLoginDialog, setShowFirstLoginDialog] = useState(false);
  const token = getToken();
  const storedUserData = Cookies.get("userData");

  const parsedUserData = storedUserData ? JSON?.parse(storedUserData) : {};
  useEffect(() => {
    dispatch(fetchUserDashboard({}));
    dispatch(fetchUserfavourites());
  }, [dispatch]);

  useEffect(() => {
    setShowFirstLoginDialog(dashboardData.is_first_login);
  }, [dashboardData.is_first_login]);

  const handleCloseFirstLoginDialog = () => {
    dispatch(fetchUserfavourites());
    setShowFirstLoginDialog(false);
  };

  const userState = useSelector((state) => state.userById.user);
  useEffect(() => {
    if (token) {
      dispatch(fetchUserById(parsedUserData?.userId, token));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getGradient = (value) => {
    return `linear-gradient(to right, lightgreen, green)`;
  };
  const examDataCard = (
    <>
      <Box className="dashboard-left-content-layout">
        <Box variant="div" component="div" className="tab-wrapper user-listing">
          <Progress.Line
            percent={0}
            status="active"
            className="custom-progress-fav"
            showInfo={false}
          />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              padding: "24px 16px",
              // paddingBottom: "8px !important",
              paddingTop: "10px !important",
              height: "68px",
            }}
          >
            <Typography variant="h3" component="h3">
              Latest Exams
            </Typography>
            <Button
              display="flex"
              alignItems="center"
              gap="5px"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/exams");
              }}
              className="primary-btn btn add-btn"
            >
              <Typography className="view-more-text">View More</Typography>
            </Button>
          </Box>
          <TableContainer className="table-listing" sx={{ minHeight: "160px" }}>
            <Table aria-label="table" className="listing-table">
              <TableBody>
                {dashboardData?.latest_exams?.length === 0 ? (
                  <TableRow className="no-msg">
                    <TableCell
                      colSpan={2}
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontSize: "16px",
                      }}
                      className="no-msg"
                    >
                      Exams Not Found
                    </TableCell>
                  </TableRow>
                ) : (
                  dashboardData?.latest_exams?.map((exam) => (
                    <TableRow
                      key={exam.id}
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                      }}
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate(`/exams/${exam?.id}/view`)}
                    >
                      <TableCell align="left">{exam.name}</TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  );

  const ComprehensionDataCard = (
    <>
      <Box className="dashboard-left-content-layout">
        <Box variant="div" component="div" className="tab-wrapper user-listing">
          <Progress.Line
            percent={0}
            status="active"
            className="custom-progress-fav"
            showInfo={false}
          />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              padding: "24px 16px",
              // paddingBottom: "8px !important",
              paddingTop: "10px !important",
              height: "68px",
            }}
          >
            <Typography variant="h3" component="h3">
              Latest Comprehensions
            </Typography>
            <Button
              display="flex"
              alignItems="center"
              gap="5px"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/comprehension");
              }}
              className="primary-btn btn add-btn"
            >
              <Typography className="view-more-text">View More</Typography>
            </Button>
          </Box>
          <TableContainer className="table-listing" sx={{ minHeight: "160px" }}>
            <Table aria-label="table" className="listing-table">
              <TableBody>
                {dashboardData?.latest_comprehensions?.length === 0 ? (
                  <TableRow className="no-msg">
                    <TableCell
                      colSpan={2}
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontSize: "16px",
                      }}
                      className="no-msg"
                    >
                      Latest Comprehension Not Found
                    </TableCell>
                  </TableRow>
                ) : (
                  dashboardData?.latest_comprehensions?.map(
                    (comprehensions) => (
                      <TableRow
                        key={comprehensions.id}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          navigate(`/comprehension/${comprehensions?.id}/view`)
                        }
                      >
                        <TableCell align="left">
                          {comprehensions.name}
                        </TableCell>
                      </TableRow>
                    )
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  );

  const grammarDataCard = (
    <>
      <Box className="dashboard-left-content-layout">
        <Box variant="div" component="div" className="tab-wrapper user-listing">
          <Progress.Line
            percent={0}
            status="active"
            className="custom-progress-fav"
            showInfo={false}
          />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              padding: "24px",
              paddingTop: "10px !important",
              height: "68px",
            }}
          >
            <Typography variant="h3" component="h3">
              Latest Grammar Rules
            </Typography>
            <Button
              display="flex"
              alignItems="center"
              gap="5px"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/grammar");
              }}
              className="primary-btn btn add-btn"
            >
              <Typography className="view-more-text">View More</Typography>
            </Button>
          </Box>
          <TableContainer className="table-listing" sx={{ minHeight: "160px" }}>
            <Table aria-label="table" className="listing-table">
              <TableBody>
                {dashboardData?.latest_grammar_rules?.length === 0 ? (
                  <TableRow className="no-msg">
                    <TableCell
                      colSpan={2}
                      rowSpan={3}
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontSize: "16px",
                      }}
                      className="no-msg"
                    >
                      Grammar Not Found
                    </TableCell>
                  </TableRow>
                ) : (
                  dashboardData?.latest_grammar_rules?.map((exam) => (
                    <TableRow
                      key={exam.id}
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                      }}
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate(`/grammar/${exam?.id}/view`)}
                    >
                      <TableCell align="left">{exam.name}</TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  );

  const myExamsCard = (
    <>
      <Box className="dashboard-left-content-layout">
        <Box
          variant="div"
          component="div"
          className="tab-wrapper user-listing"
          sx={{ position: "relative" }}
        >
          {/* Gradient Progress Bar */}
       

          <div className="progress-container">
            <div
              className="progress-filled"
              style={{
                width: `${userFavouritesData?.essay_individual_progress?.toFixed(
                  2
                )}%`,
              }}
            >
              <Tooltip
                arrow
                title={
                  <div
                    style={{ color: "white", fontSize: "13px", padding: "2px" }}
                  >
                    Progress :{" "}
                    {userFavouritesData?.essay_individual_progress?.toFixed(2)}%
                    <br /> Essay Exams Practiced : {userFavouritesData?.my_essay_completed}
                  </div>
                }
              >
                <div className="tooltip-target" />
              </Tooltip>
            </div>
            <Progress.Line
              percent={userFavouritesData?.essay_individual_progress?.toFixed(
                2
              )}
              status="active"
              className="custom-progress-fav"
              showInfo={false}
            />
          </div>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              padding: "24px",
              paddingBottom: "8px !important",
              height: "68px",
              paddingTop: 0,
            }}
          >
            <Typography variant="h3" component="h3">
              My Exams
            </Typography>
            <Button
              display="flex"
              alignItems="center"
              gap="5px"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/exams");
              }}
              className="primary-btn btn add-btn"
            >
              <Typography className="view-more-text">View More</Typography>
            </Button>
          </Box>
          <TableContainer className="table-listing" sx={{ minHeight: "160px" }}>
            <Table aria-label="table" className="listing-table">
              <TableBody>
                {userFavouritesData?.count === 0 ? (
                  <TableRow>
                    <TableCell
                      colSpan={2}
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontSize: "16px",
                      }}
                      className="no-msg"
                    >
                      Favourite Exams Not Found
                    </TableCell>
                  </TableRow>
                ) : userFavouritesData?.favourites?.essay?.slice(0, 3).length >
                  0 ? (
                  userFavouritesData?.favourites?.essay
                    .slice(0, 3)
                    .map((exam) => (
                      <TableRow
                        key={exam.id}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                        style={{ cursor: "pointer" }}
                        onClick={() => navigate(`/exams/${exam?.item_id}/view`)}
                      >
                        <TableCell align="left">{exam.item_name}</TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={2}
                      rowSpan={3}
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontSize: "16px",
                      }}
                      className="no-msg"
                    >
                      Favourite Exams Not Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  );

  const myGrammarRulesCard = (
    <>
      <Box className="dashboard-left-content-layout">
        {/* Main Card Content */}
        <Box
          variant="div"
          component="div"
          className="tab-wrapper user-listing"
          sx={{ position: "relative" }}
        >
          {/* Gradient Progress Bar */}
         
          <div className="progress-container">
            <div
              className="progress-filled"
              style={{
                width: `${userFavouritesData?.grammar_and_comprehension_individual_progress?.toFixed(
                  2
                )}%`,
              }}
            >
              <Tooltip
                arrow
                title={
                  <div
                    style={{ color: "white", fontSize: "13px", padding: "2px" }}
                  >
                    Progress :{" "}
                    {userFavouritesData?.grammar_and_comprehension_individual_progress?.toFixed(
                      2
                    )}
                    % <br /> Grammar Rules Practiced : {userFavouritesData?.my_grammar_completed}
                  </div>
                }
              >
                <div className="tooltip-target" />
              </Tooltip>
            </div>
            <Progress.Line
              percent={userFavouritesData?.grammar_and_comprehension_individual_progress?.toFixed(
                2
              )}
              status="active"
              className="custom-progress-fav"
              showInfo={false}
            />
          </div>
          {/* Content Below the Progress Bar */}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              padding: "24px",
              paddingBottom: "8px !important",
              height: "68px",
              paddingTop: 0,
            }}
          >
            <Typography variant="h3" component="h3">
              My Grammar Rules
            </Typography>
            <Button
              display="flex"
              alignItems="center"
              gap="5px"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/grammar");
              }}
              className="primary-btn btn add-btn"
            >
              <Typography className="view-more-text">View More</Typography>
            </Button>
          </Box>

          <TableContainer className="table-listing" sx={{ minHeight: "160px" }}>
            <Table aria-label="table" className="listing-table">
              <TableBody>
                {userFavouritesData?.favourites?.grammar_rule?.length === 0 ? (
                  <TableRow>
                    <TableCell
                      colSpan={2}
                      rowSpan={3}
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontSize: "16px",
                      }}
                      className="no-msg"
                    >
                      Favourite Grammar Rules Not Found
                    </TableCell>
                  </TableRow>
                ) : userFavouritesData?.favourites?.grammar_rule?.slice(0, 3)
                    ?.length > 0 ? (
                  userFavouritesData?.favourites?.grammar_rule
                    .slice(0, 3)
                    .map((grammar) => (
                      <TableRow
                        key={grammar.item_id}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          navigate(`/grammar/${grammar?.item_id}/view`)
                        }
                      >
                        <TableCell align="left">{grammar.item_name}</TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={2}
                      rowSpan={3}
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontSize: "16px",
                      }}
                      className="no-msg"
                    >
                      Favourite Grammar Rules Not Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  );

  const myComprehensionCard = (
    <>
      <Box className="dashboard-left-content-layout">
        <Box
          variant="div"
          component="div"
          className="tab-wrapper user-listing"
          sx={{ position: "relative" }}
        >
          {/* Gradient Progress Bar */}
         
          <div className="progress-container">
            <div
              className="progress-filled"
              style={{
                width: `${userFavouritesData?.grammar_and_comprehension_individual_progress?.toFixed(
                  2
                )}%`,
              }}
            >
              <Tooltip
                sx={{ backgroundColor: "black" }}
                arrow
                title={
                  <div
                    style={{ color: "white", fontSize: "13px", padding: "2px" }}
                  >
                    Overall Progress :{" "}
                    {userFavouritesData?.grammar_and_comprehension_individual_progress?.toFixed(
                      2
                    )}
                    % <br /> Comprehensions Practiced : {userFavouritesData?.my_comprehension_completed}
                  </div>
                }
              >
                <div className="tooltip-target" />
              </Tooltip>
            </div>
            <Progress.Line
              percent={userFavouritesData?.grammar_and_comprehension_individual_progress?.toFixed(
                2
              )}
              status="active"
              className="custom-progress-fav"
              showInfo={false}
            />
          </div>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              padding: "24px",
              paddingBottom: "8px !important",
              height: "68px",
              paddingTop: 0,
            }}
          >
            <Typography variant="h3" component="h3">
              My Comprehension
            </Typography>
            <Button
              display="flex"
              alignItems="center"
              gap="5px"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/comprehension");
              }}
              className="primary-btn btn add-btn"
            >
              <Typography className="view-more-text">View More</Typography>
            </Button>
          </Box>
          <TableContainer className="table-listing" sx={{ minHeight: "160px" }}>
            <Table aria-label="table" className="listing-table">
              <TableBody>
                {userFavouritesData?.count === 0 ? (
                  <TableRow>
                    <TableCell
                      colSpan={2}
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontSize: "16px",
                      }}
                      className="no-msg"
                    >
                      Favourite Comprehension Not Found
                    </TableCell>
                  </TableRow>
                ) : userFavouritesData?.favourites?.comprehension?.length >
                  0 ? (
                  userFavouritesData?.favourites?.comprehension
                    ?.slice(0, 3)
                    .map((comp) => {
                      return (
                        <TableRow
                          key={comp.item_id}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            navigate(`/comprehension/${comp?.item_id}/view`)
                          }
                        >
                          <TableCell align="left">{comp.item_name}</TableCell>
                        </TableRow>
                      );
                    })
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={2}
                      rowSpan={3}
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontSize: "16px",
                      }}
                      className="no-msg"
                    >
                      Favourite Comprehension Not Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  );

  const isAdmin = getuserData()?.isAdmin;
  return (
    <>
      {isAdmin ? (
        <Box className="content-wrapper">
          <Box className="card-wrapper">
            <Grid container spacing={3}>
              <Grid item lg={12}>
                <Typography
                  variant="h3"
                  component="h3"
                  className="card-title"
                  sx={{ marginBottom: "16px" }}
                >
                  {/* {isAdmin ? "Dashboard" : "User Dashboard"} */}
                  Dashboard
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      ) : (
        <>
          {showFirstLoginDialog && (
            <FirstLoginOnboarding onClose={handleCloseFirstLoginDialog} />
          )}
          {/* {dashboardData?.user_one_line_status && (
            <Box
              className="one-line-status"
              sx={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  display: "inline-block",
                  animation: "marquee 25s linear infinite",
                  "&:hover": {
                    animationPlayState: "paused",
                  },
                }}
              >
                <Typography>
                  Hello {userState?.first_name} 👋.&nbsp;&nbsp;
                  {dashboardData?.user_one_line_status}
                </Typography>
              </Box>

              <style>
                {`
          @keyframes marquee {
            from {
              transform: translateX(100%);
            }
            to {
              transform: translateX(-100%);
            }
          }
        `}
              </style>
            </Box>
          )} */}

          {/* My */}
          <Box className="content-wrapper">
            {/* main progress bar */}
            <MainProgressBar data={userFavouritesData} />
            <Grid container spacing={2} mt={0}>
              {/* my exams */}
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <Grid container spacing={3} style={{ flex: "1" }}>
                  <Grid item xs={12}>
                    {myExamsCard}
                  </Grid>
                </Grid>
              </Grid>

              {/* exams */}
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <Grid container spacing={3} style={{ flex: "1" }}>
                  <Grid item xs={12}>
                    {examDataCard}
                  </Grid>
                </Grid>
              </Grid>

              {/* my grammars */}
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <Grid container spacing={3} style={{ flex: "1" }}>
                  <Grid item xs={12}>
                    {myGrammarRulesCard}
                  </Grid>
                </Grid>
              </Grid>

              {/* grammars */}
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <Grid container spacing={3} style={{ flex: "1" }}>
                  <Grid item xs={12}>
                    {grammarDataCard}
                  </Grid>
                </Grid>
              </Grid>

              {/* myComps */}
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <Grid container spacing={3} style={{ flex: "1" }}>
                  <Grid item xs={12}>
                    {myComprehensionCard}
                  </Grid>
                </Grid>
              </Grid>

              {/* Cmops */}
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <Grid container spacing={3} style={{ flex: "1" }}>
                  <Grid item xs={12}>
                    {ComprehensionDataCard}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </>
  );
};

export default Dashboard;
