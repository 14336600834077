import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Snackbar, Button, Alert } from "@mui/material";
import { clearFeedback } from "../store/slices/feedback/feedbackSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const FeedbackNotification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const router = useLocation();
  const path = router.pathname;

  const { isFeedbackReady, feedbackUrl, feedbackMessage } = useSelector(
    (state) => state.feedback
  );
  
  const handleRedirect = async () => {
    await dispatch(clearFeedback()); // Clear the notification state
    if (feedbackUrl && path !== feedbackUrl) {
      navigate(feedbackUrl); // Navigate only if not already on the feedback page
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return; // Prevent closing on unintended clickaways
    }
    dispatch(clearFeedback());
    navigate(feedbackUrl); // Navigate to the feedback page
  };



  useEffect(() => {
    if (isFeedbackReady && path === feedbackUrl) {
      dispatch(clearFeedback());
    }
  }, [dispatch, isFeedbackReady, feedbackUrl, path]);
  return (
    <Snackbar
      // open={true}
      open={isFeedbackReady && path !== feedbackUrl}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      onClose={handleClose}
      className="custom-alert"
    >
      <Alert
        onClose={handleClose}
        severity="success"
        variant="filled"
        sx={{
          width: "100%",
          color: "white", // Set text color to white
           backgroundColor: "#4CAF50",
           boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)", // Add shadow
           borderRadius: "8px",
           fontSize:'15px'
        }}
        action={
          <Button color="secondary" size="small" onClick={handleRedirect} sx={{ color: 'white', fontWeight: 600 }}>
            View
          </Button>
        }
      >
        {/* Exam feedback is generated! */}
        {feedbackMessage}
      </Alert>
    </Snackbar>

  );
};

export default FeedbackNotification;
