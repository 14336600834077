import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  MenuItem
} from "@mui/material";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { successToast } from "../../../response/successToast";
import { getToken } from "../../../utils/token";
import { IoMdArrowRoundBack } from "react-icons/io";
import * as Yup from "yup";
import { callApi } from "../../../api";
import { editGrammar } from "../../../store/slices/grammar/editGrammarByIdSlice";
import { errorToast } from "../../../response/errorToast";
import { setLoading } from "../../../store/slices/loader/loaderSlice";
import Loader from "../../../common/loader";
import { fetchCategories, updateCategory } from "../../../store/slices/category/categorySlice";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Please enter grammar rule name"),
  explanation_prompt: Yup.string().required("Please enter explanation prompt"),
  exam_prompt: Yup.string().required("Please enter exam prompt"),
  feedback_prompt: Yup.string().required("Please enter feedback prompt"),
  question_count: Yup.number().required("Please enter question count"),
  category: Yup.string().required("Please Select a category"),
});

const EditGrammar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [grammar, setGrammar] = useState("");
  const [settings, setSettings] = useState("");
  const [categories, setCategories] = useState([]);
  const { id } = useParams();
  const token = getToken();
  const formik = useFormik({
    initialValues: {
      name: "",
      explanation_prompt: "",
      exam_prompt: "",
      feedback_prompt: "",
      question_type: "mcq",
      question_count: "",
      category: ""
    },
    validationSchema,
    onSubmit: (values) => {
      dispatch(editGrammar(id, values, token))
        .then((response) => {
          if (response.success) {
            successToast("Grammar updated successfully");
            navigate("/grammar");

            // If "Remember Me" is checked, store the credentials
          } else {
            console.error("Login failed. Please check your credentials.");
          }
        })
        .catch((error) => {
          console.error("API call error:", error);
        });
    },
  });
  const { isLoading } = useSelector((state) => state.loader);

  const fetchData = async () => {
    try {
      dispatch(setLoading(true));
      const response = await callApi({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/grammar/view/${id}/`,
      });
      setGrammar(response);
    } catch (error) {
      console.log(error);
      errorToast(error.response.data.errors);
      if (
        error.response &&
        error.response.data &&
        error.response.data.errors &&
        error.response.data.errors.includes(
          "Grammar rule with given id does not exist"
        )
      ) {
        navigate("*");
      }
    } finally {
      dispatch(setLoading(false));
    }
  };

  const fetchViewData = async () => {
    try {
      const response = await callApi({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/settings/view`,
      });
      setSettings(response);
    } catch (error) {
      console.error(error);
      errorToast(error.response.data.errors);
      // if (error?.message === "You have no permission to view this leave.") {
      //   navigate("/noaccess");
      // } else if (error?.message === "Leave not found") {
      //   navigate("*");
      // }
    }
  };

  const fetchGrammarRulesData = async () => {
    try {
      const response = await dispatch(fetchCategories(module = "grammar", token));
      if (response.success) {
        const categoryList = response.data.grammar_rule_categories || [];
        setCategories(categoryList);
        if (grammar?.data?.category_id) {
          // Find the matching category to ensure it exists in our options
          const matchingCategory = categoryList.find(cat => cat.id === grammar.data.category_id);
          if (matchingCategory) {
            formik.setFieldValue('category', matchingCategory.name);
          }
        }
      }
    } catch (error) {
      errorToast(error.message);
    }
  };


  const updateGrammarRulesData = async () => {
    try {
      const selectedCategory = formik.values.category;

      // Validate if category is selected
      if (!selectedCategory) {
        errorToast("Please select a category before updating.");
        return;
      }

      // Validate if there's any actual change in category
      if (selectedCategory === grammar?.data?.category) {
        // Optional: Show a message that no change is needed
        successToast("Category is already up to date.");
        return;
      }

      const response = await dispatch(updateCategory({
        module: "grammar",
        categoryId: selectedCategory,
        grammarId: id,
        token
      }));

      if (response.success) {
        // Update local state if needed
        setGrammar(prev => ({
          ...prev,
          data: {
            ...prev.data,
            category: selectedCategory
          }
        }));

        // Refresh categories list if needed
        await fetchGrammarRulesData();

        // Show success message
        successToast("Category updated successfully");
      } else {
        // Handle unsuccessful update
        errorToast(response.message || "Failed to update category");
      }
    } catch (error) {
      errorToast(error.message || "Error updating category");
      console.error("Category update error:", error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchViewData();
    fetchGrammarRulesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    formik.setValues({
      name: grammar?.data?.name || "",
      explanation_prompt: grammar?.data?.explanation_prompt || "",
      exam_prompt: grammar?.data?.exam_prompt || "",
      feedback_prompt: grammar?.data?.feedback_prompt || "",
      question_type: grammar?.data?.question_type || "",
      question_count: grammar?.data?.question_count || "",
      category: categories?.some(cat => cat.id === grammar?.data?.category_id)
        ? grammar?.data?.category_id
        : "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grammar, settings,categories]);

  const handleCategoryChange = async (event) => {
    const newValue = event.target.value;
    formik.setFieldValue('category', newValue);
  };
  // const handleChange = (event) => {
  //   const { name, value } = event.target;

  //   // Check if the selected question type is "mcq"
  //   const isMcq = value === "mcq";

  //   // Set the exam prompt based on the selected question type
  //   // console.log("Grammar Exam Prompt:", grammar?.data?.exam_prompt);
  //   // console.log(
  //   //   "Short Practice Prompt:",
  //   //   settings?.settings?.GRAMMAR_RULE_SHORT_PRACTICE_PROMPT
  //   // );
  //   // console.log(
  //   //   "Practice Prompt:",
  //   //   settings?.settings?.GRAMMAR_RULE_PRACTICE_PROMPT
  //   // );
  //   // console.log(isMcq);
  //   let examPrompt;
  //   let feedbackPrompt;
  //   if (previousQuestionType === "mcq" && isMcq) {
  //     // If the previous question type and the current selection are both "mcq",
  //     // use the exam prompt from the API response
  //     examPrompt = grammar?.data?.exam_prompt || "";
  //     feedbackPrompt = grammar?.data?.feedback_prompt || "";
  //   } else {
  //     // Otherwise, set the exam prompt based on the current selection
  //     examPrompt = isMcq
  //       ? settings?.settings?.GRAMMAR_RULE_PRACTICE_PROMPT
  //       : settings?.settings?.GRAMMAR_RULE_SHORT_PRACTICE_PROMPT;

  //     feedbackPrompt = isMcq
  //       ? settings?.settings?.GRAMMAR_RULE_FEEDBACK_PROMPT
  //       : grammar?.settings?.GRAMMAR_RULE_SHORT_FEEDBACK_PROMPT;
  //   }

  //   // if (previousQuestionType === "mcq" && isMcq) {
  //   //   // If the previous question type and the current selection are both "mcq",
  //   //   // use the exam prompt from the API response
  //   //   feedbackPrompt = grammar?.data?.feedback_prompt || "";
  //   // } else {
  //   //   // Otherwise, set the exam prompt based on the current selection
  //   //   feedbackPrompt = isMcq
  //   //     ? settings?.settings?.GRAMMAR_RULE_FEEDBACK_PROMPT
  //   //     : grammar?.settings?.GRAMMAR_RULE_SHORT_FEEDBACK_PROMPT;
  //   // }

  //   console.log("Exam Prompt:", examPrompt, feedbackPrompt);

  //   // Update formik values
  //   formik.setValues({
  //     ...formik.values,
  //     [name]: value,
  //     exam_prompt: examPrompt,
  //     feedback_prompt: feedbackPrompt,
  //   });

  //   // Update the previous question type
  //   previousQuestionType = value;
  // };

  let previousQuestionType = "";
  const handleChange = (event) => {
    const { name, value } = event.target;

    // Check if the selected question type is "mcq"
    const isMcq = value === "mcq";

    // Set the exam prompt based on the selected question type
    let examPrompt;
    let feedbackPrompt;
    if (isMcq) {
      // If the selected question type is "mcq"
      examPrompt = settings?.settings?.GRAMMAR_RULE_PRACTICE_PROMPT;
      feedbackPrompt = settings?.settings?.GRAMMAR_RULE_FEEDBACK_PROMPT;
    } else {
      // If the selected question type is not "mcq" (assuming it's "short_answers")
      examPrompt = settings?.settings?.GRAMMAR_RULE_SHORT_PRACTICE_PROMPT;
      feedbackPrompt = settings?.settings?.GRAMMAR_RULE_SHORT_FEEDBACK_PROMPT;
    }

    // console.log("Exam Prompt:", examPrompt, "Feedback Prompt:", feedbackPrompt);

    // Update formik values
    formik.setValues({
      ...formik.values,
      [name]: value,
      exam_prompt: examPrompt,
      feedback_prompt: feedbackPrompt,
    });

    // Update the previous question type
    previousQuestionType = value;
  };

  // const shortExamPrompt = !isMcq
  // ? grammar?.data?.exam_prompt || ""
  // : settings?.settings?.GRAMMAR_RULE_PRACTICE_PROMPT || "";
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Box className="content-header-title">
            <Button className="action-btn" onClick={() => navigate(`/grammar`)}>
              <IoMdArrowRoundBack className="icon-font" />
            </Button>
            <Typography variant="h2" component="h2">
              Edit Grammar Rule
            </Typography>
          </Box>
          <Box className="content-layout">
            <Box variant="div" component="div" className="createuser-wrap p-24">
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={4}>
                  <Grid item sm={12} md={12} lg={12}>
                    <Box variant="div" component="div" className="user-detail">
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                          <Box display="flex" justifyContent="space-between" alignItems="center" gap="16px">
                            {/* Grammar Rule Name Input */}
                            <Box flex={1}>
                              <Typography
                                variant="body1"
                                component="label"
                                className="label"
                              >
                                Grammar Rule Name
                              </Typography>
                              <TextField
                                className="input-field"
                                fullWidth
                                id="outlined-basic"
                                variant="outlined"
                                size="small"
                                placeholder="Enter Grammar Rule Name"
                                name="name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={
                                  formik.touched.name && formik.errors.name ? (
                                    <span className="error-message">
                                      {formik.errors.name}
                                    </span>
                                  ) : null
                                }
                              />
                            </Box>

                            {/* Grammar Rule Category Dropdown */}
                            <Box flex={1}>
                              <Typography
                                variant="body1"
                                component="label"
                                className="label"
                              >
                                Grammar Rule Category
                              </Typography>
                              <TextField
                                select
                                className="input-field"
                                fullWidth
                                id="category"
                                variant="outlined"
                                size="small"
                                placeholder="Select Category"
                                name="category"
                                value={formik.values.category}
                                onChange={handleCategoryChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.category && Boolean(formik.errors.category)}
                                helperText={
                                  formik.touched.category && formik.errors.category ? (
                                    <span className="error-message">
                                      {formik.errors.category}
                                    </span>
                                  ) : null
                                }
                              >
                                {categories && categories.length > 0 ? (
                                  categories.map((category) => (
                                    <MenuItem
                                      key={category.id}
                                      value={category.id}
                                    >
                                      {category.name}
                                    </MenuItem>
                                  ))
                                ) : (
                                  <MenuItem disabled>No categories available</MenuItem>
                                )}
                              </TextField>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          <Box variant="div" component="div">
                            <Typography
                              variant="body1"
                              component="label"
                              className="label"
                            >
                              Question Type
                            </Typography>
                            <RadioGroup
                              aria-label="question"
                              name="question_type"
                              value={formik.values.question_type || "mcq"}
                              onChange={handleChange}
                              onBlur={formik.handleBlur}
                              style={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <FormControlLabel
                                value="mcq"
                                control={<Radio />}
                                label="Multiple Choice Questions"
                              />
                              <FormControlLabel
                                value="short_answers"
                                control={<Radio />}
                                label="Short Answers"
                              />
                              {/* Add more FormControlLabel components for each option */}
                            </RadioGroup>
                            {formik.touched.question_type &&
                              formik.errors.question_type ? (
                              <div className="error-message">
                                {formik.errors.question_type}
                              </div>
                            ) : null}
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          <Box variant="div" component="div">
                            <Typography
                              variant="body1"
                              component="label"
                              className="label"
                            >
                              Question Count
                            </Typography>
                            <TextField
                              className="input-field"
                              fullWidth
                              id="outlined-basic"
                              label=""
                              variant="outlined"
                              size="small"
                              placeholder="Enter Question Count"
                              name="question_count"
                              type="number"
                              value={formik.values.question_count}
                              onChange={(event) => {
                                const newValue = parseInt(event.target.value);
                                if (!isNaN(newValue) && newValue >= 1) {
                                  formik.handleChange(event);
                                } else {
                                  // Value is negative or not a number
                                  // You can set it to zero or display an error message
                                  formik.setFieldValue("question_count", 1); // Set it to zero
                                  // OR
                                  // formik.setFieldError('question_count', 'Question count cannot be negative'); // Display an error message
                                }
                              }}
                              onBlur={formik.handleBlur}
                              error={
                                formik.touched.question_count &&
                                Boolean(formik.errors.question_count)
                              }
                              helperText={
                                formik.touched.question_count &&
                                  formik.errors.question_count ? (
                                  <span
                                    classquestion_count="error-message"
                                    style={{
                                      color: "#ee5e5e",
                                      marginLeft: "-10px",
                                    }}
                                  >
                                    {formik.errors.question_count}
                                  </span>
                                ) : null
                              }
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <Box variant="div" component="div">
                            <Typography
                              variant="body1"
                              component="label"
                              className="label"
                            >
                              Explanation Prompt
                            </Typography>

                            <TextField
                              multiline
                              rows={5}
                              className="input-field"
                              placeholder="Enter Explanation Prompt"
                              name="explanation_prompt"
                              value={formik.values.explanation_prompt}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              sx={{ width: "100%" }}
                              error={
                                formik.touched.explanation_prompt &&
                                Boolean(formik.errors.explanation_prompt)
                              }
                              helperText={
                                formik.touched.explanation_prompt &&
                                  formik.errors.explanation_prompt ? (
                                  <span className="error-message">
                                    {formik.errors.explanation_prompt}
                                  </span>
                                ) : null
                              }
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <Box variant="div" component="div">
                            <Typography
                              variant="body1"
                              component="label"
                              className="label"
                            >
                              Exam Prompt
                            </Typography>

                            <TextField
                              multiline
                              rows={5}
                              className="input-field"
                              placeholder="Enter Exam Prompt"
                              name="exam_prompt"
                              value={formik.values.exam_prompt}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              sx={{ width: "100%" }}
                              error={
                                formik.touched.exam_prompt &&
                                Boolean(formik.errors.exam_prompt)
                              }
                              helperText={
                                formik.touched.exam_prompt &&
                                  formik.errors.exam_prompt ? (
                                  <span className="error-message">
                                    {formik.errors.exam_prompt}
                                  </span>
                                ) : null
                              }
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <Box variant="div" component="div">
                            <Typography
                              variant="body1"
                              component="label"
                              className="label"
                            >
                              Feedback Prompt
                            </Typography>

                            <TextField
                              multiline
                              rows={5}
                              className="input-field"
                              placeholder="Enter Feedback Prompt"
                              name="feedback_prompt"
                              value={formik.values.feedback_prompt}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              sx={{ width: "100%" }}
                              error={
                                formik.touched.feedback_prompt &&
                                Boolean(formik.errors.feedback_prompt)
                              }
                              helperText={
                                formik.touched.feedback_prompt &&
                                  formik.errors.feedback_prompt ? (
                                  <span className="error-message">
                                    {formik.errors.feedback_prompt}
                                  </span>
                                ) : null
                              }
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
                <Box
                  variant="div"
                  component="div"
                  className="footer-btn"
                  style={{ marginTop: "16px", height: "35px" }}
                >
                  <Box variant="div" component="div" className="btn-group">
                    <Button
                      variant="contained"
                      size="large"
                      className="primary-btn btn"
                      type="submit"
                      disableRipple
                    >
                      {" "}
                      Save
                    </Button>
                  </Box>
                </Box>
              </form>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};
export default EditGrammar;
