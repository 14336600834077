import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Container, CircularProgress } from "@mui/material";
import logo from "../assets/images/logo.png";
import "../styles/exam_overview.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// import { setLoading } from "../store/slices/loader/loaderSlice";
import { callApi } from "../api";
import { errorToast } from "../response/errorToast";
import { useDispatch } from "react-redux";

const ExamOverview = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const params = useParams();
  const [examDataById, setExamDataById] = useState();

  const [loading, setLoading] = useState(true);
 
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await callApi({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/exams/view/${params.id}/`,
      });
   
      if (response?.data?.exam_overview_details && Object.keys(response?.data?.exam_overview_details)?.length > 0) {
        setExamDataById(response?.data);
        setLoading(false);
       
      } else {
        setTimeout(fetchData, 3000); // Call fetchData again after 3 seconds
      }
    } catch (error) {
      // console.log(error);
      setLoading(false);
      errorToast(error?.response?.data?.errors);
      if (
        error?.response &&
        error?.response?.data &&
        error?.response?.data?.errors &&
        error?.response?.data?.errors?.includes("Exam with given id does not exist")
      ) {
        navigate("*");
      }
    } 
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch,]);
  return (
    <div className="overview-container">
      {loading ?
        <Box>
          <Box sx={{ marginTop: '30%', }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <CircularProgress />
            </Box>
            <Typography sx={{ fontWeight: 600, color: 'white', fontSize: "25px" }}>Exam Information is being generated, This may take some time...</Typography>
          </Box>

        </Box>
        :
        <Box >
          <Box className="header">
            <img src={logo} />
            <Typography className="exam-title">{examDataById?.name} Exam Instructions</Typography>
          </Box>
          {

           !examDataById ?
              <Box sx={{ marginTop: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography sx={{ fontWeight: 600, color: 'white', fontSize: "25px" }}>Exam Information not available</Typography>
              </Box> :
              <Box className="content">

                {examDataById?.exam_overview_details?.introduction_and_purpose && <Box className='intro'>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                    <Typography className="title-content">

                      {examDataById?.exam_overview_details?.introduction_and_purpose?.title || 'Introduction and Purpose'} :
                    </Typography>
                    <Box className="sub-info">
                      {/* Loop over OpenEssayMe_recommendations */}
                      {typeof examDataById?.exam_overview_details?.introduction_and_purpose === 'string' ? (
                        <Typography className="text-content">
                          {examDataById?.exam_overview_details?.introduction_and_purpose}
                        </Typography>
                      ) : (
                        Object.entries(examDataById?.exam_overview_details?.introduction_and_purpose).map(([key, value]) => {
                          const toTitleCase = (str) =>
                            str
                              .toLowerCase()
                              .split('_') // Split words by underscores
                              .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter of each word
                              .join(' '); // Rejoin words with a space

                          return (
                            key !== 'title' &&
                            <Box key={key} sx={{ marginBottom: '16px' }}>
                              <Typography className="sub-title-content" sx={{ fontWeight: 'bold' }}>
                                {toTitleCase(key)}:
                              </Typography>
                              <Typography className="text-content">
                                {typeof value === 'string' ? value : JSON.stringify(value)}
                              </Typography>
                            </Box>
                          );
                        })
                      )}
                    </Box>
                  </Box>

                  <Box className="exam-image-container">
                    <img
                      // src={`${process.env.REACT_APP_API_URL}/media/${examDataById?.exam_image}`} // local
                      src={`${process.env.REACT_APP_AWS_S3_DOMAIN}/media/${examDataById?.exam_image}`} // prod
                      alt="Illustration"
                      className="floating-image"
                    />
                  </Box>
                </Box>}


                {examDataById?.exam_overview_details?.OpenEssayMe_recommendations && <Box className="OpenEssayMe-recommendations card">
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                    <Typography className="title-content">
                      {examDataById?.exam_overview_details?.OpenEssayMe_recommendations?.title || 'OpenEssayMe Recommendations for the Exam'} :
                    </Typography>
                    <Box className="sub-info">
                      {/* Loop over OpenEssayMe_recommendations */}
                      {typeof examDataById?.exam_overview_details?.OpenEssayMe_recommendations === 'string' ? (
                        <Typography className="text-content">
                          {examDataById?.exam_overview_details?.OpenEssayMe_recommendations}
                        </Typography>
                      ) : (
                        Object.entries(examDataById?.exam_overview_details?.OpenEssayMe_recommendations).map(([key, value]) => {
                          const toTitleCase = (str) =>
                            str
                              .toLowerCase()
                              .split('_') // Split words by underscores
                              .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter of each word
                              .join(' '); // Rejoin words with a space

                          return (
                            key !== 'title' &&
                            <Box key={key} sx={{ marginBottom: '16px' }}>
                              <Typography className="sub-title-content" sx={{ fontWeight: 'bold' }}>
                                {toTitleCase(key)}:
                              </Typography>
                              <Typography className="text-content">
                                {typeof value === 'string' ? value : JSON.stringify(value)}
                              </Typography>
                            </Box>
                          );
                        })
                      )}
                    </Box>
                  </Box>
                </Box>}

                {examDataById?.exam_overview_details?.essay_section && <Box className="essay-section card">
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                    <Typography className="title-content">
                      {examDataById?.exam_overview_details?.essay_section?.title || 'Essay Section'} :
                    </Typography>
                    <Box className="sub-info">
                      {/* Loop over OpenEssayMe_recommendations */}
                      {typeof examDataById?.exam_overview_details?.essay_section === 'string' ? (
                        <Typography className="text-content">
                          {examDataById?.exam_overview_details?.essay_section}
                        </Typography>
                      ) : (
                        Object.entries(examDataById?.exam_overview_details?.essay_section).map(([key, value]) => {
                          const toTitleCase = (str) =>
                            str
                              .toLowerCase()
                              .split('_') // Split words by underscores
                              .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter of each word
                              .join(' '); // Rejoin words with a space

                          return (
                            key !== 'title' &&
                            <Box key={key} sx={{ marginBottom: '16px' }}>
                              <Typography className="sub-title-content" sx={{ fontWeight: 'bold' }}>
                                {toTitleCase(key)}:
                              </Typography>
                              <Typography className="text-content">
                                {typeof value === 'string' ? value : JSON.stringify(value)}
                              </Typography>
                            </Box>
                          );
                        })
                      )}
                    </Box>
                  </Box>
                </Box>}

                <Box sx={{ display: 'flex', gap: '30px' }}>
                  {examDataById?.exam_overview_details?.test_structure && <Box className="test-structure card">
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                      <Typography className="title-content">
                        {examDataById?.exam_overview_details?.test_structure?.title || 'Test Structure'} :
                      </Typography>
                      <Box className="sub-info">
                        {/* Loop over OpenEssayMe_recommendations */}
                        {typeof examDataById?.exam_overview_details?.test_structure === 'string' ? (
                          <Typography className="text-content">
                            {examDataById?.exam_overview_details?.test_structure}
                          </Typography>
                        ) : (
                          Object.entries(examDataById?.exam_overview_details?.test_structure).map(([key, value]) => {
                            const toTitleCase = (str) =>
                              str
                                .toLowerCase()
                                .split('_') // Split words by underscores
                                .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter of each word
                                .join(' '); // Rejoin words with a space

                            return (
                              key !== 'title' &&
                              <Box key={key} sx={{ marginBottom: '16px' }}>
                                <Typography className="sub-title-content" sx={{ fontWeight: 'bold' }}>
                                  {toTitleCase(key)}:
                                </Typography>
                                <Typography className="text-content">
                                  {typeof value === 'string' ? value : JSON.stringify(value)}
                                </Typography>
                              </Box>
                            );
                          })
                        )}
                      </Box>
                    </Box>
                  </Box>}


                  {examDataById?.exam_overview_details?.scoring && <Box className="scoring card">
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                      <Typography className="title-content">
                        {examDataById?.exam_overview_details?.scoring?.title || 'Scoring'} :
                      </Typography>
                      <Box className="sub-info">
                        {/* Loop over OpenEssayMe_recommendations */}
                        {typeof examDataById?.exam_overview_details?.scoring === 'string' ? (
                          <Typography className="text-content">
                            {examDataById?.exam_overview_details?.scoring}
                          </Typography>
                        ) : (
                          Object.entries(examDataById?.exam_overview_details?.scoring).map(([key, value]) => {
                            const toTitleCase = (str) =>
                              str
                                .toLowerCase()
                                .split('_') // Split words by underscores
                                .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter of each word
                                .join(' '); // Rejoin words with a space

                            return (
                              key !== 'title' &&
                              <Box key={key} sx={{ marginBottom: '16px' }}>
                                <Typography className="sub-title-content" sx={{ fontWeight: 'bold' }}>
                                  {toTitleCase(key)}:
                                </Typography>
                                <Typography className="text-content">
                                  {typeof value === 'string' ? value : JSON.stringify(value)}
                                </Typography>
                              </Box>
                            );
                          })
                        )}
                      </Box>
                    </Box>
                  </Box>}
                </Box>

                <Box sx={{ display: 'flex', gap: '30px' }}>
                  {examDataById?.exam_overview_details?.costs && <Box className="costs card">
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                      <Typography className="title-content">
                        {examDataById?.exam_overview_details?.costs?.title || 'Costs'} :
                      </Typography>
                      <Box className="sub-info">
                        {/* Loop over OpenEssayMe_recommendations */}
                        {typeof examDataById?.exam_overview_details?.costs === 'string' ? (
                          <Typography className="text-content">
                            {examDataById?.exam_overview_details?.costs}
                          </Typography>
                        ) : (
                          Object.entries(examDataById?.exam_overview_details?.costs).map(([key, value]) => {
                            const toTitleCase = (str) =>
                              str
                                .toLowerCase()
                                .split('_') // Split words by underscores
                                .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter of each word
                                .join(' '); // Rejoin words with a space

                            return (
                              key !== 'title' &&
                              <Box key={key} sx={{ marginBottom: '16px' }}>
                                <Typography className="sub-title-content" sx={{ fontWeight: 'bold' }}>
                                  {toTitleCase(key)}:
                                </Typography>
                                <Typography className="text-content">
                                  {typeof value === 'string' ? value : JSON.stringify(value)}
                                </Typography>
                              </Box>
                            );
                          })
                        )}
                      </Box>
                    </Box>
                  </Box>}

                  {examDataById?.exam_overview_details?.testing_availability && <Box className="testing-availability card">
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                      <Typography className="title-content">
                        {examDataById?.exam_overview_details?.testing_availability?.title || 'Testing Availability'} :
                      </Typography>
                      <Box className="sub-info">
                        {/* Loop over OpenEssayMe_recommendations */}
                        {typeof examDataById?.exam_overview_details?.testing_availability === 'string' ? (
                          <Typography className="text-content">
                            {examDataById?.exam_overview_details?.testing_availability}
                          </Typography>
                        ) : (
                          Object.entries(examDataById?.exam_overview_details?.testing_availability).map(([key, value]) => {

                            const toTitleCase = (str) =>
                              str
                                .toLowerCase()
                                .split('_') // Split words by underscores
                                .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter of each word
                                .join(' '); // Rejoin words with a space

                            return (
                              key !== 'title' && <Box key={key} sx={{ marginBottom: '16px' }}>
                                <Typography className="sub-title-content" sx={{ fontWeight: 'bold' }}>
                                  {toTitleCase(key)}:
                                </Typography>
                                <Typography className="text-content">
                                  {typeof value === 'string' ? value : JSON.stringify(value)}
                                </Typography>
                              </Box>
                            );
                          })
                        )}
                      </Box>
                    </Box>
                  </Box>}

                </Box>
              </Box>}
        </Box>}
    </div>
  );
};

export default ExamOverview;
