import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorToast } from "../../../response/errorToast";

const initialState = {
  onEditComprehension: [],
  status: "idle",
  error: null,
};

const editComprehensionSlice = createSlice({
  name: "editComprehension",
  initialState,
  reducers: {
    updateComprehensionStart: (state) => {
      state.status = "loading";
    },
    updateComprehensionSuccess: (state, action) => {
      state.status = "succeeded";
      state.onEditComprehension = action.payload.data;
    },
    updateComprehensionFailure: (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
    },
  },
});

export const {
  updateComprehensionStart,
  updateComprehensionSuccess,
  updateComprehensionFailure,
} = editComprehensionSlice.actions;

export const editComprehension = (id, examData, token) => async (dispatch) => {
  try {
    // dispatch(setLoading(true));
    dispatch(updateComprehensionStart());
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/comprehension/update/${id}`,
      examData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    dispatch(updateComprehensionSuccess(response));
    return { success: true, message: response?.message };
  } catch (error) {
    errorToast(error.response.data.errors);
    dispatch(updateComprehensionFailure(error.response.data));
  } finally {
    // dispatch(setLoading(false));
  }
};

export default editComprehensionSlice.reducer;
