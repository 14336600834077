import { createSlice } from "@reduxjs/toolkit";

const feedbackSlice = createSlice({
  name: "feedback",
  initialState: {
    isFeedbackReady: false,
    feedbackUrl: null,
    feedbackMessage:null
  },
  reducers: {
    setFeedbackReady: (state, action) => {
      state.isFeedbackReady = true;
      state.feedbackUrl = action.payload.url;
      state.feedbackMessage=action.payload.message;
      
    },
    clearFeedback: (state) => {
      state.isFeedbackReady = false;
      state.feedbackUrl = null;
      state.feedbackMessage = null;
     
    },
  },
});

export const { setFeedbackReady, clearFeedback } = feedbackSlice.actions;

export default feedbackSlice.reducer;
