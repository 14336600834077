import {
  Box,
  Typography,
  TableContainer,
  TableBody,
  Table,
  TableHead,
  TableCell,
  TableRow,
  Button,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MdModeEditOutline } from "react-icons/md";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { GrFormViewHide } from "react-icons/gr";
import { fetchComprehension } from "../../../store/slices/comprehension/comprehensionSlice";
import DeleteComprehensionModal from "./deleteComprehensionModal";
import getuserData from "../../../utils/userData";
import Loader from "../../../common/loader";

const ComprehensionList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isAdmin = getuserData()?.isAdmin;
  const { isLoading } = useSelector((state) => state.loader);
  const comprehension = useSelector((state) => state.comprehension.comprehension);
  const [searchQuery, setSearchQuery] = useState("");
  const [open, setOpen] = useState(false);
  const [id, setId] = useState();
  const [filteredComprehension, setFilteredComprehension] = useState([]);
  useEffect(() => {
    dispatch(fetchComprehension({}));
  }, [dispatch]);

  const handleDeleteOpen = (id) => {
    setOpen(true);
    setId(id);
  };

  const handleDeleteClose = () => {
    setOpen(false);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    if (comprehension?.comprehensions) {
      let filtered = [...comprehension?.comprehensions];



      // Apply search filter
      if (searchQuery) {
        const query = searchQuery?.toLowerCase();
        filtered = filtered?.filter(
          (comprehension) =>
            comprehension?.name?.toLowerCase().includes(query) ||
            (comprehension?.category_name &&
              comprehension?.category_name.toLowerCase().includes(query))
        );
      }


      setFilteredComprehension(filtered);
    }
  }, [
    comprehension?.comprehensions,
    searchQuery,
  ]);
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Box className="content-header" height="35px">
            <Box sx={{ width:'30% !important',}}>
              <TextField
                placeholder="Search comprehension..."
                variant="outlined"
                size="small"
                value={searchQuery}
                onChange={handleSearchChange}
                sx={{  width:'100% !important',backgroundColor:'white'  }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />

            </Box>
            <Button
              variant="contained"
              size="large"
              className="primary-btn btn add-btn"
              disableRipple
              onClick={() => navigate("/comprehension/create")}
            >
              <Typography variant="body1" component="p">
                Create Comprehension
              </Typography>
            </Button>
          </Box>

          <Box className="content-layout" marginTop={isAdmin ? "24px" : "32px"}>
            <Box
              variant="div"
              component="div"
              className="tab-wrapper user-listing"
            >
              <TableContainer className="table-listing">
                <Table
                  // sx={{ minWidth: 650 }}
                  aria-label="table"
                  className="listing-table"
                >
                  <TableHead>
                    <TableRow className="head-row">
                      <TableCell align="left">Comprehension Name</TableCell>
                      {/* <TableCell align="left">Added On</TableCell> */}
                      {/* <TableCell align="left">Feedback Prompt</TableCell> */}
                      <TableCell align="left">Actions</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {filteredComprehension?.length === 0 ? (
                      <TableRow className="no-msg">
                        <TableCell
                          colSpan={2}
                          style={{
                            width: "100%",
                            textAlign: "center",
                            fontSize: "16px",
                          }}
                          className="no-msg"
                        >
                          Comprehension Not Found
                        </TableCell>
                      </TableRow>
                    ) : (
                      filteredComprehension?.map((exam) => (
                        <TableRow
                          key={exam.id}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell align="left">{exam.name}</TableCell>
                          {/* <TableCell align="left">
                        {moment(exam?.created_at).format("MM-DD-YYYY")}
                      </TableCell> */}
                          {/* <TableCell align="left"></TableCell> */}
                          <TableCell
                            align="left"
                            // sx={{ padding: "16px 16px 16px 0px !important" }}
                            style={{
                              display: "flex",
                              gap: "20px",
                            }}
                          >
                            <Button
                              className="listing-action-btn"
                              onClick={() =>
                                navigate(`/comprehension/${exam?.id}/view`)
                              }
                              title="View Comprehension"
                            >
                              <GrFormViewHide
                                className="icon-font"
                                style={{ marginBottom: "5px" }}
                              />
                            </Button>
                            <Button
                              className="listing-action-btn"
                              onClick={() =>
                                navigate(`/comprehension/${exam?.id}/edit`)
                              }
                              title="Edit Comprehension"
                            >
                              <MdModeEditOutline className="icon-font" />
                            </Button>
                            <Button
                              className="listing-action-btn"
                              onClick={() => handleDeleteOpen(exam?.id)}
                              title="Delete Comprehension"
                            >
                              <RiDeleteBin5Fill className="icon-font" />
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </>
      )}
      <DeleteComprehensionModal
        open={open}
        setOpen={setOpen}
        handleDeleteClose={handleDeleteClose}
        id={id}
      />
    </>
  );
};

export default ComprehensionList;
