import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  TableContainer,
  TableBody,
  Table,
  TableCell,
  TableRow,
  TableHead,
  Tooltip,
} from "@mui/material";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import { callApi } from "../../../api";
import { errorToast } from "../../../response/errorToast";
import moment from "moment";
import { GoAlertFill } from "react-icons/go";
import { setSubscriptionFromApi } from "../../../store/slices/subscription/selectors";
import Loader from "../../../common/loader";
import { setLoading } from "../../../store/slices/loader/loaderSlice";

const ViewUserExam = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const examDataById = useSelector((state) => state.examById.exam);
  const [examDataById, setExamDataById] = useState();
  const userState = useSelector((state) => state.userById.user);
  const [isSubscribed, setisSubscribed] = useState(false);
  // const [isCopying, setIsCopying] = useState(false);
  // const clipboardButtonRef = useRef(null);
  const { id } = useParams();
  const formik = useFormik({
    initialValues: {
      name: "", // Set initial values from stored credentials
      description: "",
    },
  });

  const fetchData = async () => {
    try {
      dispatch(setLoading(true));
      const response = await callApi({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/exams/view/${id}/`,
      });
      setExamDataById(response);
    } catch (error) {
      // console.log(error);
      errorToast(error.response.data.errors);
      if (
        error.response &&
        error.response.data &&
        error.response.data.errors &&
        error.response.data.errors.includes("Exam with given id does not exist")
      ) {
        navigate("*");
      }
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    formik.setValues({
      name: examDataById?.data?.name || "",
      description: examDataById?.data?.description || "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [examDataById]);

  const handleInfoNavigation = () => {
    const url = `/exam-overview/${id}`;
    window.open(url, '_blank'); 
  };

  // let clipboardInstance = null;
  // let isToastDisplayed = false;

  // const copyLinkHandler = async () => {
  //   if (isCopying) {
  //     return;
  //   }
  //   setIsCopying(true);
  //   const Link = examDataById?.data?.uuid;
  //   const examLink = `${process.env.REACT_APP_API_URL}/exam-instructions/${Link}`;
  //   if (clipboardInstance) {
  //     clipboardInstance.destroy();
  //   }

  //   clipboardInstance = new ClipboardJS(clipboardButtonRef.current, {
  //     text: () => examLink,
  //   });

  //   clipboardInstance.on("success", () => {
  //     if (!isToastDisplayed) {
  //       toast.success("Copied to clipboard");
  //       isToastDisplayed = true;
  //     }
  //     clipboardInstance.destroy();
  //     setIsCopying(false);
  //   });

  //   clipboardButtonRef.current.click();
  // };
  const { isLoading } = useSelector((state) => state.loader);
  const subscription = useSelector(setSubscriptionFromApi);
  const listOfPlans = [
    "tutor_monthly",
    "intermediate_monthly",
    "basic_monthly",
    "tutor_six_monthly",
    "intermediate_six_monthly",
    "basic_six_monthly",
    "basic_yearly",
    "tutor_yearly",
    "intermediate_yearly",
  ];
  // const isSubscribed =
  //   subscription && listOfPlans.some((plan) => subscription.includes(plan));

  useEffect(() => {
    // If no subscription, check comprehension practice
    if (!subscription) {
      if (userState?.essay_practice_left === 0) {
        setisSubscribed(false);

        return;
      } else {
        setisSubscribed(userState?.essay_practice_left !== 0);
        return;
      }
    }
    // Check if the subscription includes any valid plans
    const hasValidPlan = listOfPlans?.some((plan) =>
      subscription.includes(plan)
    );
    setisSubscribed(!!hasValidPlan);
  }, [subscription, userState]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : isSubscribed ? (
        <>
          <Box className="content-header-title">
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="100%"
            >
              <Button className="action-btn" onClick={() => navigate(`/exams`)}>
                <IoMdArrowRoundBack className="icon-font" />
              </Button>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography variant="h2" component="h2">
                  View Exam
                </Typography>

                <Box display="flex" gap="10px" height="35px">
                  {/* <Button
                    variant="contained"
                    size="large"
                    className="primary-btn btn add-btn"
                    disableRipple
                    onClick={copyLinkHandler}
                    ref={clipboardButtonRef}
                  >
                    <Typography variant="body1" component="p">
                      Copy Information Link
                    </Typography>
                  </Button> */}
                  <Button
                    variant="contained"
                    size="large"
                    className="primary-btn btn add-btn"
                    disableRipple
                    onClick={handleInfoNavigation}
                  >
                    <Typography variant="body1" component="p">
                      Exam Overview
                    </Typography>
                  </Button>
                  {/* <Button
                    variant="contained"
                    size="large"
                    className="primary-btn btn"
                    // type="submit"
                    disableRipple
                    onClick={() => {
                      navigate(`/exams/${examDataById?.data?.id}/attempt`);
                    }}
                  >
                    {" "}
                    Attempt Practice
                  </Button> */}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="content-layout" marginTop="34px">
            <Box variant="div" component="div" className="createuser-wrap p-24">
              <Grid container spacing={4}>
                <Grid item sm={12} md={12} lg={12}>
                  <Box variant="div" component="div" className="user-detail">
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <Box variant="div" component="div">
                          <Typography
                            variant="body1"
                            component="label"
                            className="label"
                          >
                            Name of Exam
                          </Typography>

                          <Typography
                            variant="body2"
                            component="span"
                            className="value"
                          >
                            {formik.values.name}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Box variant="div" component="div">
                          <Typography
                            variant="body1"
                            component="label"
                            className="label"
                          >
                            Description
                          </Typography>

                          <Typography
                            variant="body2"
                            component="span"
                            className="value"
                          >
                            {formik.values.description}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
              <Box
                variant="div"
                component="div"
                className="footer-btn"
                style={{ marginTop: "16px", height: "40px" }}
              >
                <Box variant="div" component="div" className="btn-group">
                <Button
                    variant="contained"
                    size="large"
                    className="primary-btn btn"
                    // type="submit"
                    disableRipple
                    onClick={() => {
                      navigate(`/exams/${examDataById?.data?.id}/attempt`);
                    }}
                  >
                    {" "}
                    Practice Test
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="content-layout">
            <TableContainer className="table-listing">
              <Table
                // sx={{ minWidth: 650 }}
                aria-label="table"
                className="listing-table"
              >
                <TableHead>
                  <TableRow className="head-row">
                    <TableCell align="left">Previous Attempts</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {examDataById?.data?.attempts?.length === 0 ? (
                    <TableRow className="no-msg">
                      <TableCell
                        style={{
                          width: "100%",
                          textAlign: "center",
                          fontSize: "16px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        className="no-msg"
                      >
                        No attempts found for this exam{" "}
                      </TableCell>
                    </TableRow>
                  ) : (
                    examDataById?.data?.attempts?.map((exam, index) => (
                      <TableRow
                        key={exam.id}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          navigate(`/exams/${exam?.id}/exam-feedback`)
                        }
                      >
                        <TableCell align="left" key={exam?.id} colSpan={2}>
                          Attempted on{" "}
                          {moment(exam?.created_at).format("MM-DD-YYYY")}
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </>
      ) : (
        <Box className="content-layout" marginTop="32px">
          <Box
            variant="div"
            component="div"
            className="tab-wrapper user-listing"
          >
            <Box className="access-denied-found">
              <Box className="page-inner">
                <GoAlertFill fontSize="65px" style={{ marginBottom: "40px" }} />
                {/* <Typography variant="h3" component="h3">
                  Access Denied
                </Typography>
                <Typography variant="body1" component="p">
                  You are not subscribed to Essay Practice Module. Please
                  purchase atleast basic plan to access it.
                </Typography> */}

                <Typography
                  variant="h3"
                  component="h3"
                  style={{ textTransform: "initial" }}
                >
                  <Box>
                    You have No free Essay Exam Left. Please Upgrade to the
                    atleast Basic plan.
                  </Box>
                </Typography>
                <Typography variant="body1" component="p">
                  <Box>
                    Please subscribe to join our vibrant community of learners!
                  </Box>
                </Typography>
                <Box style={{ height: "35px" }}>
                  <Button
                    variant="contained"
                    size="large"
                    className="primary-btn btn add-btn"
                    disableRipple
                    onClick={() => navigate(`/my-plan`)}
                  >
                    Upgrade Plan
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};
export default ViewUserExam;
