import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  CircularProgress,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { callApi } from "../../../api";
import { errorToast } from "../../../response/errorToast";
import { IoMdArrowRoundBack } from "react-icons/io";
import moment from "moment";
import { setFeedbackReady } from "../../../store/slices/feedback/feedbackSlice";

const GrammarResponseFeedback = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [grammar, setGrammar] = useState();
  const userState = useSelector((state) => state.userById.user);
  // eslint-disable-next-line no-unused-vars
  const [feedbackDone, setFeedbackDone] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    try {
      const response = await callApi({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/grammar-practice/view/${id}/`,
      });
      setGrammar(response);
      if (response?.attempt?.feedback_status === "done") {
        setFeedbackDone(true);
        setIsLoading(false);
        dispatch(
          setFeedbackReady({url:`/grammar/${response?.attempt?.id}/grammar-feedback`,message:'Grammar Rule Feedback is Generated'})
        );
      } else {
        setTimeout(fetchData, 3000); // Call fetchData again after 2 seconds
      }
    } catch (error) {
      // console.log(error);
      errorToast(error.response.data.errors);
      if (
        error.response &&
        error.response.data &&
        error.response.data.errors &&
        error.response.data.errors.includes("Exam with given id does not exist")
      ) {
        navigate("*");
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const getQuestionBackgroundColor = (question, optionId) => {
    if (optionId === question.correct_option) {
      return "#9ad8ff"; // Correct option
    } else if (
      question.selected_option === question.correct_option &&
      optionId === question.selected_option
    ) {
      return "#9ad8ff"; // Selected and correct option
    } else if (optionId === question.selected_option) {
      return "#ff8e97"; // Selected but incorrect option
    } else {
      return ""; // Default background color
    }
  };
  const checkHasGrammarAccess = () => {
    if (userState?.grammar_rule_practice_left === 0) {
      navigate("/grammar");
    }
  };
  return (
    <>
      <Box className="content-header-title">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          <Button
            className="action-btn"
            startIcon={<IoMdArrowRoundBack className="icon-font" />}
            onClick={() => {
              checkHasGrammarAccess();
              navigate(`/grammar/${grammar?.attempt?.grammar_rule_id}/view`);
            }}
          ></Button>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Typography variant="h2" component="h2">
              View Practice Attempted&nbsp; (
              {moment(grammar?.attempt?.created_at).format("MM-DD-YYYY")})
            </Typography>
            {isLoading ? null : (
              <>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  gap={2}
                  alignItems="center"
                >
                  <Typography variant="h2" component="h2">
                    Marks: {grammar?.marks}
                  </Typography>
                  <Button
                    variant="contained"
                    size="large"
                    className="primary-btn btn add-btn"
                    disableRipple
                    onClick={() => {
                      checkHasGrammarAccess();
                      navigate(
                        `/grammar/${grammar?.attempt?.grammar_rule_id}/attempt`
                      );
                    }}
                  >
                    <Typography variant="body1" component="p">
                      Try Again
                    </Typography>
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>
      {grammar?.question_type === "mcq" ? (
        <Box className="content-layout">
          <Box variant="div" component="div" className="createuser-wrap p-24">
            <Grid container spacing={4}>
              <Grid item sm={12} md={12} lg={12}>
                {isLoading ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                    alignItems="center"
                    mt={2}
                  >
                    <Typography
                      variant="body2"
                      component="span"
                      className="value"
                      style={{ fontWeight: "600" }}
                    >
                      Give Openessayme a moment to review, will provide you a
                      feedback shortly..... !!!!!
                    </Typography>
                    <CircularProgress style={{ marginTop: "10px" }} />
                    <Typography
                    variant="body2"
                    component="span"
                    className="value"
                    style={{ fontWeight: "600" }}
                  >
                    You will be notified when the Grammar Rule feedback is generated meanwhile you can practice with other modules. 
                  </Typography>
                  </Box>
                ) : (
                  <Box variant="div" component="div" className="user-detail">
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        {grammar?.paragraph && (
                          <Typography
                            variant="body1"
                            component="label"
                            className="label"
                            mb={4}
                          >
                            Passage: <br />
                            {grammar?.paragraph}
                          </Typography>
                        )}
                        {grammar?.feedback?.map((question) => (
                          <>
                            <Box
                              key={question.id}
                              variant="div"
                              component="div"
                              mb={4}
                            >
                              <Typography
                                variant="body1"
                                component="label"
                                className="label"
                              >
                                {question?.id}) {question?.question}
                              </Typography>
                              <RadioGroup
                                aria-label={`question-${question?.id}`}
                                name={`question_type_${question?.id}`}
                                style={{
                                  marginBottom: "20px",
                                  // cursor: "default",
                                  color: "white",
                                }}
                              >
                                {question.options.map((option) => (
                                  <FormControlLabel
                                    key={option.id}
                                    value={option.id.toString()}
                                    control={<Radio />}
                                    label={option.text}
                                    checked={
                                      option?.id === question?.selected_option
                                    }
                                    style={{
                                      backgroundColor:
                                        getQuestionBackgroundColor(
                                          question,
                                          option.id
                                        ),
                                      marginBottom: "5px",
                                      marginLeft: "0px",
                                      borderRadius: "5px",
                                      marginRight: "0px",
                                    }}
                                  />
                                ))}
                              </RadioGroup>
                              <Typography
                                variant="body1"
                                component="label"
                                className="label"
                              >
                                Feedback:
                              </Typography>
                              <Typography
                                variant="body2"
                                component="span"
                                className="value"
                                // style={{ marginTop: "10px" }}
                              >
                                {question?.feedback}
                              </Typography>
                              <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Divider className="divider" />
                              </Grid>
                            </Box>
                          </>
                        ))}
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>
        </Box>
      ) : (
        <Box className="content-layout">
          <Box variant="div" component="div" className="createuser-wrap p-24">
            <Grid container spacing={4}>
              <Grid item sm={12} md={12} lg={12}>
                {isLoading ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                    alignItems="center"
                    mt={2}
                  >
                    <Typography
                      variant="body2"
                      component="span"
                      className="value"
                      style={{ fontWeight: "600" }}
                    >
                      Give Openessayme a moment to review, will provide you a
                      feedback shortly.....
                    </Typography>
                    <CircularProgress style={{ marginTop: "10px" }} />
                    <Typography
                    variant="body2"
                    component="span"
                    className="value"
                    style={{ fontWeight: "600" }}
                  >
                    You will be notified when the Grammar Rule feedback is generated meanwhile you can practice with other modules. 
                  </Typography>
                  </Box>
                ) : (
                  <Box variant="div" component="div" className="user-detail">
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        {grammar?.paragraph && (
                          <Typography
                            variant="body1"
                            component="label"
                            className="label"
                            mb={4}
                          >
                            Passage: <br />
                            {grammar?.paragraph}
                          </Typography>
                        )}
                        {grammar?.feedback?.map((question) => (
                          <>
                            <Box
                              key={question.id}
                              variant="div"
                              component="div"
                              mb={4}
                            >
                              <Box
                                display="flex"
                                justifyContent="space-between"
                              >
                                <Typography
                                  variant="body1"
                                  component="label"
                                  className="label"
                                >
                                  {question.id}) {question?.question}
                                </Typography>
                                <Typography
                                  variant="body1"
                                  component="label"
                                  className={`label ${
                                    question?.score > 0
                                      ? "green-score"
                                      : "red-score"
                                  }`}
                                  style={{
                                    marginBottom: "5px",
                                    color: `${
                                      question?.score > 0
                                        ? "#B0DC4C"
                                        : "#E8515E"
                                    }`,
                                  }}
                                >
                                  ({question?.score})
                                </Typography>
                              </Box>
                              <Typography
                                variant="body1"
                                component="label"
                                className="label"
                              >
                                Entered Answer:
                              </Typography>
                              <Typography
                                variant="body2"
                                component="span"
                                className="value"
                                // style={{ marginLeft: "15px" }}
                              >
                                {question?.entered_answer}
                              </Typography>
                              <Typography
                                variant="body1"
                                component="label"
                                className="label"
                              >
                                Feedback:
                              </Typography>
                              <Typography
                                variant="body2"
                                component="span"
                                className="value"
                                // style={{ marginTop: "10px" }}
                              >
                                {question?.feedback}
                              </Typography>
                              <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Divider className="divider" />
                              </Grid>
                            </Box>
                          </>
                        ))}
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
    </>
  );
};
export default GrammarResponseFeedback;
