import { AppBar, Chip, ListItemIcon, Toolbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Box, Typography, Avatar, Button, Menu, MenuItem } from "@mui/material";
import { FiUser, FiLogOut } from "react-icons/fi";
import { MdPublishedWithChanges } from "react-icons/md";
import logo from "../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import { getRefreshToken } from "../utils/token";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../store/slices/authentication/logoutSlice";
import getuserData from "../utils/userData";
import Cookies from "js-cookie";
import { setSubscriptionFromApi } from "../store/slices/subscription/selectors";
import { setRoleFromApi } from "../store/slices/role/selectors";
import ChangePasswordModal from "../pages/auth/changePassword";
import { useCookies } from "react-cookie";

const ChatbotHeader = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  // const [userMenuAnchorEl, setUserMenuAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  // const isUserMenuOpen = Boolean(userMenuAnchorEl);
  const [cookies] = useCookies();
  const subscription = useSelector(setSubscriptionFromApi);
  const [modalOpen, setModalOpen] = useState(false);
  const [userData, setUserData] = useState(getuserData());
  useEffect(() => {
    // This effect will run whenever cookies change
    const updatedCookieUserData = getuserData();
    setUserData(updatedCookieUserData);
  }, [cookies]);
  const isAdmin = useSelector(setRoleFromApi);
  const freeTrial = userData?.isFreeTrialActive;
  const planMapping = {
    tutor_monthly: { title: "WritePro Premium Tutor", validity: "Monthly" },
    intermediate_monthly: {
      title: "WritePlus Intermediate",
      validity: "Monthly",
    },
    basic_monthly: { title: "WriteWay Basic", validity: "Monthly" },
    tutor_six_monthly: {
      title: "WritePro Premium Tutor",
      validity: " Half Yearly",
    },
    intermediate_six_monthly: {
      title: "WritePlus Intermediate",
      validity: " Half Yearly",
    },
    basic_six_monthly: { title: "WriteWay Basic", validity: " Half Yearly" },
    tutor_yearly: { title: "WritePro Premium Tutor", validity: "Yearly" },
    intermediate_yearly: {
      title: "WritePro Premium Tutor",
      validity: "Yearly",
    },
    basic_yearly: { title: "WriteWay Basic", validity: "Yearly" },
  };
  let plan = subscription;
  if (freeTrial === true) {
    plan = { title: "Free Trial" };
  } else if (plan) {
    plan = planMapping[subscription];
  } else {
    plan = { title: "No Active Plan" };
  }
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const handleUserMenuClick = (event) => {
  //   setUserMenuAnchorEl(event.currentTarget);
  // };

  // const handleUserMenuClose = () => {
  //   setUserMenuAnchorEl(null);
  // };

  const token = Cookies.get("accessToken");
  const refreshToken = getRefreshToken();
  const logoutHandler = async () => {
    try {
      await dispatch(logout(token, refreshToken));
      handleClose();
      Cookies.remove("accessToken");
      navigate("/home", { replace: true });
    } catch (error) {}
  };

  const handleDeleteClose = () => {
    setModalOpen(false);
  };

  const userName = userData?.userName;
  const firstName = userData?.firstName;
  const lastName = userData?.lastName;
  const profileImage = userData?.profileImg;
  const firstLetter = userName ? userName.charAt(0) : "";
  if (!token) return null;

  return (
    <>
      <AppBar
        position="fixed"
        // open={openSideMenu}
        className={`navbar`}
      >
        <Toolbar>
          <Box
            variant="div"
            component="div"
            className="navbar-left"
            sx={{ cursor: "pointer" }}
            onClick={() => navigate("/home")}
          >
            <img
              src={logo}
              alt="logo-main"
              loading="lazy"
              className="large-logo"
              style={{ cursor: "pointer", height: "40px", width: "40px" }}
            />

            <Typography
              variant="h3"
              noWrap
              component="h3"
              style={{ color: "white", fontSize: "20px" }}
            >
              OPENESSAYME
            </Typography>
          </Box>

          <Box variant="div" component="div" className="navbar-right">
            <Box variant="div" component="div">
              <Typography variant="h1" noWrap component="h1">
                OpenEssayMe Tutor
              </Typography>
            </Box>

            {/* <Box variant="div" component="div" className="options-menu">
            <Menu
              id="basic-menu"
              className="user-info-menu more-options"
              anchorEl={userMenuAnchorEl}
              open={isUserMenuOpen}
              onClose={handleUserMenuClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
               
              }}
            >
              <MenuItem
                onClick={() => navigate("/exams")}
                style={{ justifyContent: "space-between" }}
              >
                <span>Essay Practice</span>
              </MenuItem>
              <MenuItem
                onClick={() => navigate("/grammar")}
                style={{ justifyContent: "space-between" }}
              >
                <span>Grammar Rules</span>
              </MenuItem>
              {isAdmin ? (
                <MenuItem
                  onClick={() => navigate("/users")}
                  style={{ justifyContent: "space-between" }}
                >
                  <span>Users</span>
                </MenuItem>
              ) : null}
              {isAdmin ? (
                <MenuItem
                  onClick={() => navigate("/settingss")}
                  style={{ justifyContent: "space-between" }}
                >
                  <span>Settings</span>
                </MenuItem>
              ) : null}
            </Menu>
          </Box> */}
            <Box variant="div" component="div" className="user-info">
              {!isAdmin && (
                <Chip
                  label={plan?.title}
                  style={{
                    color: plan?.title !== "No Active Plan" ? "white" : "",
                    // fontWeight: 600,
                    fontSize: "22px",
                    backgroundColor:
                      plan?.title !== "No Active Plan"
                        ? "#43acef !important"
                        : "#ff8e97 !important",
                    height: "35px",
                    cursor: "pointer",
                  }}
                  className={
                    plan?.title !== "No Active Plan"
                      ? "subscribe"
                      : "unsubscribe"
                  }
                  onClick={() => navigate("/my-plan")}
                />
              )}
              {/* {profileImage ? (
                <Avatar src={profileImage}></Avatar>
              ) : (
                <Avatar>{firstLetter}</Avatar>
              )}{" "} */}
              {/* <Button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                disableRipple
                style={{ textTransform: "initial" }}
              >
                <span className="mr-1" mr={2}>
                  {firstName && lastName ? (
                    <>
                      {firstName}&nbsp;{lastName}
                    </>
                  ) : (
                    userName
                  )}
                </span>
              </Button> */}
              {profileImage ? (
                <Avatar src={profileImage} onClick={handleClick}></Avatar>
              ) : (
                <Avatar onClick={handleClick}>{firstLetter}</Avatar>
              )}
              {/* <Menu
                id="basic-menu"
                className="user-info-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                 
                }}
              >
                <MenuItem
                  style={{ justifyContent: "space-between" }}
                >
                  <span>Profile</span>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setModalOpen(true);
                    handleClose();
                  }}
                  style={{ justifyContent: "space-between" }}
                >
                  <span>Change Password</span>
                </MenuItem>
                <MenuItem
                  onClick={submitHandler}
                  style={{
                    justifyContent: "space-between",
                 
                    width: "100%",
                  }}
                >
                  <span>Logout</span>
                </MenuItem>
              </Menu> */}
              <Menu
                className="account-dropdown-menu"
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.15))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{
                  horizontal: "right",
                  vertical: "top",
                }}
                anchorOrigin={{
                  horizontal: "right",
                  vertical: "bottom",
                }}
              >
                <Box className="user-details">
                  <Box
                    className="user-img"
                    sx={{
                      padding: `${userData?.imgUrl ? "0px" : "8px"}`,
                    }}
                  >
                    {profileImage ? (
                      <Avatar src={profileImage} onClick={handleClick}></Avatar>
                    ) : (
                      <Avatar onClick={handleClick}>{firstLetter}</Avatar>
                    )}
                  </Box>
                  <Typography
                    variant="h5"
                    component="h5"
                    className="text-green user-name"
                    align="center"
                  >
                    {firstName && lastName ? (
                      <>
                        {firstName}&nbsp;{lastName}
                      </>
                    ) : (
                      userName
                    )}
                  </Typography>
                  {isAdmin && (
                    <Typography
                      variant="span"
                      component="span"
                      className="text-grey user-position"
                      align="center"
                      // sx={{border:'2px solid red'}}
                    >
                      Admin
                    </Typography>
                  )}
                </Box>
                <hr className="divider-border" />
                <Box className="organization-details">
                  {/* <Box className="org-img">
                    <img src={logo} alt="account img" />
                  </Box> */}
                  <Box
                    className="org-text"
                    onClick={() => navigate("/my-plan")}
                  >
                    
                    
                  </Box>
                </Box>
                <Box className="links">
                  <MenuItem
                    className="menu-link"
                    onClick={() => navigate("/profile")}
                  >
                    <ListItemIcon className="link-icon">
                      <FiUser />
                    </ListItemIcon>
                    <span class="link-text">My Profile</span>
                  </MenuItem>
                  {/* <a
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      color: "#232323",
                      textDecoration: "none",
                    }}
                    onClick={() => {
                      setModalOpen(true);
                      handleClose();
                    }}
                  > */}
                  <MenuItem
                    className="menu-link"
                    onClick={() => {
                      setModalOpen(true);
                      handleClose();
                    }}
                  >
                    <ListItemIcon className="link-icon">
                      <MdPublishedWithChanges />
                    </ListItemIcon>
                    <span class="link-text">Change Password</span>
                  </MenuItem>
                  {/* </a> */}
                  <MenuItem className="menu-link" onClick={logoutHandler}>
                    <ListItemIcon className="link-icon">
                      <FiLogOut />
                    </ListItemIcon>
                    <span class="link-text">Logout</span>
                  </MenuItem>
                </Box>
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <ChangePasswordModal
        open={modalOpen}
        setOpen={setModalOpen}
        handleDeleteClose={handleDeleteClose}
      />
    </>
  );
};
export default ChatbotHeader;
