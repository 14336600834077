import {
  Box,
  Typography,
  TableContainer,
  TableBody,
  Table,
  TableHead,
  TableCell,
  TableRow,
  Button,
  Chip,
  Tooltip,
  IconButton,
  TextField,
  InputAdornment,
  MenuItem,
} from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MdModeEditOutline } from "react-icons/md";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { GrFormViewHide } from "react-icons/gr";
import { fetchGrammar } from "../../../store/slices/grammar/grammarSlice";
import DeleteGrammarModal from "./deleteGrammarModal";
import getuserData from "../../../utils/userData";
import Loader from "../../../common/loader";
import FiberNewIcon from '@mui/icons-material/FiberNew';
import { LuBadgePlus } from "react-icons/lu";
import { GoDotFill } from "react-icons/go";
import { fetchCategories } from "../../../store/slices/category/categorySlice";
import { getToken } from "../../../utils/token";


const GrammarList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = getToken();
  const isAdmin = getuserData()?.isAdmin;
  const { isLoading } = useSelector((state) => state.loader);
  const grammar = useSelector((state) => state.grammar.grammar);
  const [open, setOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [searchQuery, setSearchQuery] = useState("");
  const [categories, setCategories] = useState([]);
  const [filteredRules, setFilteredRules] = useState([]);
  const [id, setId] = useState();
  const [isdeleted, setisdeleted] = useState(false);


  const handleDeleteOpen = (id) => {
    setOpen(true);
    setId(id);
  };

  const handleDeleteClose = () => {

    setOpen(false);
  };
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  useEffect(() => {
    if (grammar?.grammar_rules) {
      let filtered = [...grammar?.grammar_rules];
      // Apply category filter
      // if (selectedCategory !== "all") {
      //   filtered = filtered?.filter(
      //     (rule) => rule.category_name === selectedCategory
      //   );
      // }

      // Apply search filter
      if (searchQuery) {
        const query = searchQuery?.toLowerCase();
        filtered = filtered?.filter(
          (rule) =>
            rule.name.toLowerCase().includes(query) ||
            (rule.category_name &&
              rule.category_name.toLowerCase().includes(query))
        );
      }

      // Sort filtered rules by category_name in ascending order
      // filtered?.sort((a, b) => a.category_name.localeCompare(b.category_name));

      setFilteredRules(filtered);
    }
  }, [
    grammar?.grammar_rules,
    // selectedCategory,
    searchQuery,
  ]);
  const fetchGrammarCategories = async () => {
    try {
      const response = await dispatch(
        fetchCategories((module = "grammar"), token)
      );

      if (response.success) {
        setCategories(response.data.grammar_rule_categories || []);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    dispatch(fetchGrammar({}));
    // fetchGrammarCategories()
  }, [dispatch]);
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Box className="content-header" >
            <Box sx={{ width: '30% !important' }}>
              <TextField
                placeholder="Search grammar rules..."
                variant="outlined"
                size="small"
                value={searchQuery}
                onChange={handleSearchChange}
                sx={{ width: '100% !important', backgroundColor: 'white' }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
              {/* <TextField
                select
                size="small"
                value={selectedCategory}
                onChange={handleCategoryChange}
                sx={{ minWidth: 200, mt: 2 }}
              >
                <MenuItem value="all">All Categories</MenuItem>
                {categories.map((category) => (
                  <MenuItem key={category?.name} value={category?.name}>
                    {category?.name}
                  </MenuItem>
                ))}
              </TextField> */}
            </Box>
            <Button
              variant="contained"
              size="large"
              className="primary-btn btn add-btn"
              disableRipple
              onClick={() => navigate("/grammar/create")}
            >
              <Typography variant="body1" component="p">
                Create Grammar Rule
              </Typography>
            </Button>
          </Box>

          <Box className="content-layout" marginTop={isAdmin ? "24px" : "32px"}>
            <Box
              variant="div"
              component="div"
              className="tab-wrapper user-listing"
            >
              <TableContainer className="table-listing">
                <Table
                  // sx={{ minWidth: 650 }}
                  aria-label="table"
                  className="listing-table"
                >
                  <TableHead>
                    <TableRow className="head-row">
                      <TableCell align="left">Rule Name</TableCell>
                      {/* <TableCell align="left">Added On</TableCell> */}
                      {/* <TableCell align="left">Feedback Prompt</TableCell> */}
                      <TableCell align="left">Actions</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {filteredRules?.length === 0 ? (
                      <TableRow className="no-msg">
                        <TableCell
                          colSpan={2}
                          style={{
                            width: "100%",
                            textAlign: "center",
                            fontSize: "16px",
                          }}
                          className="no-msg"
                        >
                          Grammar Rules Not Found
                        </TableCell>
                      </TableRow>
                    ) : (
                      filteredRules?.map((exam) => (
                        <TableRow
                          key={exam.id}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell align="left">{exam.name} {exam?.is_suggested && !exam.is_reviewed && (
                            <Tooltip title={<div
                              style={{ color: "white", fontSize: "13px", padding: "2px" }}
                            >Newly Suggested by AI, Click to Review</div>} arrow placement="right">
                              <IconButton
                                onClick={() =>
                                  navigate(`/grammar/${exam?.id}/view`)
                                }

                              >
                                {/* <GoDotFill fill="#3f51b5" fontSize="small"/> */}
                                <Typography sx={{ fontSize: '12px', fontWeight: 600, color: '#3f51b5 !important' }}>NEW</Typography>
                              </IconButton>
                            </Tooltip>
                          )}</TableCell>
                          {/* <TableCell align="left">
                        {moment(exam?.created_at).format("MM-DD-YYYY")}
                      </TableCell> */}
                          {/* <TableCell align="left"></TableCell> */}
                          <TableCell
                            align="left"
                            // sx={{ padding: "16px 16px 16px 0px !important" }}
                            style={{
                              display: "flex",
                              gap: "20px",
                            }}
                          >
                            <Button
                              className="listing-action-btn"
                              onClick={() =>
                                navigate(`/grammar/${exam?.id}/view`)
                              }
                              title="View Grammar"
                            >
                              <GrFormViewHide
                                className="icon-font"
                                style={{ marginBottom: "5px" }}
                              />
                            </Button>
                            <Button
                              className="listing-action-btn"
                              onClick={() =>
                                navigate(`/grammar/${exam?.id}/edit`)
                              }
                              title="Edit Grammar"
                            >
                              <MdModeEditOutline className="icon-font" />
                            </Button>
                            <Button
                              className="listing-action-btn"
                              onClick={() => handleDeleteOpen(exam?.id)}
                              title="Delete Grammar"
                            >
                              <RiDeleteBin5Fill className="icon-font" />
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </>
      )}
      <DeleteGrammarModal
        open={open}

        setOpen={setOpen}
        handleDeleteClose={handleDeleteClose}
        id={id}
      />
    </>
  );
};

export default GrammarList;
